<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Kontakt</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"><i class="fa fa-home"></i></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Kontakt</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- contact area start -->
    <div class="contact-area section-space">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="contact-message">
              <h2>Pošaljite nam poruku</h2>
              <form id="contact-form" @submit.prevent="sendEmail" class="contact-form">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input name="user_name" placeholder="Vaše Ime *" type="text" required />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input name="user_phone" placeholder="Telefon *" type="text" required />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input name="user_email" placeholder="Email *" type="text" required />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <input name="contact_subject" placeholder="Tema *" type="text" />
                  </div>
                  <div class="col-12">
                    <div class="contact2-textarea text-center">
                      <textarea placeholder="Poruka *" name="message" class="form-control2" required=""></textarea>
                    </div>
                    <div class="contact-btn">
                      <button class="btn btn__bg" type="submit" v-if="!loading">Pošalji</button>
                      <button type="button" disabled class="btn btn__bg" v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Pošalji
                      </button>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <p class="form-message" :class="{ error: errMsg }">
                      {{ formMessage }}
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="contact-info">
              <h2>Kontaktirajte nas</h2>
              <p>Tupperware - kvalitetni i ekološki pomagači u kuhinji</p>
              <ul>
                <li><i class="fa fa-map-marker"></i> Dimitrija Tucovića 148 Beograd</li>
                <li><i class="fa fa-phone"></i> +381112437660, +381631749024</li>
                <li><i class="fa fa-envelope-o"></i> ikotim.tw@gmail.com</li>
              </ul>
              <div class="working-time">
                <h3>Radno vreme</h3>
                <p><span>Ponedeljak – Petak:</span>09AM – 17PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- contact area end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import emailjs from "emailjs-com";

export default {
  metaInfo: {
    title: "Kontakt",
  },
  data() {
    return {
      formMessage: "",
      errMsg: false,
      loading: false,
    };
  },
  methods: {
    sendEmail(e) {
      this.loading = true;

      emailjs
        .sendForm("service_thecdnb", "template_ce2eysj", e.target, "user_QtRYrcF5gUGPelQxuU2R3")
        .then(() => {
          const form = document.getElementById("contact-form");
          form.reset();

          this.formMessage = "Uspešno ste poslali poruku.";
          this.errMsg = false;
          this.loading = false;
        })
        .catch(() => {
          this.formMessage = "Greška! Izvinite, došlo je do greške, pokušajte ponovo.";

          this.errMsg = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-message {
  color: #008000;

  &.error {
    color: #e74c3c;
  }
}
</style>

<template>
  <div>
    <!-- Start Footer Area Wrapper -->
    <footer class="footer-wrapper common-bg">
      <!-- footer widget area start -->
      <div class="footer-widget-area">
        <div class="container">
          <div class="footer-widget-inner section-space">
            <div class="row mbn-30">
              <!-- footer widget item start -->
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="footer-widget-item mb-30">
                  <div class="footer-widget-logo">
                    <router-link to="/pocetna">
                      <img src="../assets/img/logo/tupperware-logo.png" alt="logo tupperware" />
                      <img src="../assets/img/logo/logo.png" alt="logo" />
                    </router-link>
                  </div>
                  <ul class="footer-widget-body">
                    <li class="widget-text">Tupperware - kvalitetni i ekološki pomagači u kuhinji</li>
                    <li class="address">
                      <em>Adresa:</em>
                      Dimitrija Tucovića 148 Beograd
                    </li>
                    <li class="phone">
                      <em>Kontakt telefon:</em>
                      <a href="tel:+381112437660">+381112437660</a>,
                      <a href="tel:+381631749024">+381631749024</a>
                    </li>
                    <li class="email">
                      <em>e-mail:</em>
                      <a href="mailto:ikotim.tw@gmail.com">ikotim.tw@gmail.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- footer widget item end -->

              <!-- footer widget item start -->
              <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="footer-widget-item mb-30">
                  <div class="footer-widget-title">
                    <h5>Mapa</h5>
                  </div>
                  <ul class="footer-widget-body">
                    <li><router-link to="/pocetna">Početna</router-link></li>
                    <li><router-link to="/o-nama">O nama</router-link></li>
                    <li>
                      <router-link to="/proizvodi">Proizvodi</router-link>
                    </li>
                    <li><router-link to="/recepti">Recepti</router-link></li>
                    <li><router-link to="/kontakt">Kontakt</router-link></li>
                  </ul>
                </div>
              </div>
              <!-- footer widget item end -->

              <!-- footer widget item start -->
              <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="footer-widget-item mb-30">
                  <div class="footer-widget-title">
                    <h5>Dodaci</h5>
                  </div>
                  <ul class="footer-widget-body">
                    <li>
                      <a href="/files/OBAVESTENJE-O-REKLAMACIJAMA-1.doc" download>Obaveštenje o reklamacijama</a>
                    </li>
                    <li>
                      <a href="/files/zahtev-za-reklamaciju-2.doc" download>Zahtev za reklamaciju</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- footer widget item end -->

              <!-- footer widget item start -->
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="footer-widget-item mb-30">
                  <div class="footer-widget-title">
                    <h5>Ostanite povezani</h5>
                  </div>
                  <div class="footer-widget-body">
                    <!-- newsletter area start -->
                    <div class="newsletter-inner">
                      <p>
                        Prijavite se na naš Newsletter i ostanite u toku sa najnovijim proizvodima, trendovima i
                        specijalnim ponudama.
                      </p>

                      <form
                        action="https://gmail.us1.list-manage.com/subscribe/post?u=cc9b099ab6f3d2351b9b9f80d&amp;id=462a1a2497"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="validate"
                        target="_blank"
                        novalidate
                      >
                        <input
                          type="email"
                          class="news-field"
                          name="EMAIL"
                          id="mce-EMAIL"
                          autocomplete="off"
                          placeholder="Unesite vaš email"
                        />
                        <button class="news-btn" type="submit" name="subscribe" id="mc-embedded-subscribe">
                          Prijavi se
                        </button>
                      </form>
                      <!-- mailchimp-alerts Start -->
                      <div class="mailchimp-alerts">
                        <div class="mailchimp-submitting"></div>
                        <!-- mailchimp-submitting end -->
                        <div class="mailchimp-success"></div>
                        <!-- mailchimp-success end -->
                        <div class="mailchimp-error"></div>
                        <!-- mailchimp-error end -->
                      </div>
                      <!-- mailchimp-alerts end -->
                    </div>
                    <!-- newsletter area start -->

                    <!-- footer widget social link start -->
                    <div class="footer-social-link">
                      <a
                        href="https://www.facebook.com/TupperwareSrbija1"
                        class="facebook"
                        data-toggle="tooltip"
                        title="Facebook"
                        target="_blank"
                        ><i class="fa fa-facebook"></i
                      ></a>
                      <a
                        href="https://www.instagram.com/tupperwareikotim/?hl=en"
                        class="instagram"
                        data-toggle="tooltip"
                        title="Instagram"
                        target="_blank"
                        ><i class="fa fa-instagram"></i
                      ></a>
                      <a
                        href="https://www.youtube.com/channel/UCs85xR2-taRqGkyIfw6CfLA"
                        class="youtube"
                        data-toggle="tooltip"
                        title="Youtube"
                        target="_blank"
                        ><i class="fa fa-youtube"></i
                      ></a>
                    </div>
                    <!-- footer widget social link end -->
                  </div>
                </div>
              </div>
              <!-- footer widget item end -->
            </div>
          </div>
        </div>
      </div>
      <!-- footer widget area end -->

      <!-- footer bottom area start -->
      <div class="footer-bottom-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <div class="copyright-text">
                <p>
                  Copyright © 2021
                  <router-link to="/pocetna">Ikotim</router-link>. Sva prava zadržana.
                </p>
              </div>
            </div>
            <div class="col-md-6 order-1 order-md-2" />
          </div>
        </div>
      </div>
      <!-- footer bottom area end -->
    </footer>
    <!-- End Footer Area Wrapper -->

    <!-- offcanvas mini cart start -->
    <div class="offcanvas-minicart-wrapper">
      <div class="minicart-inner">
        <div class="offcanvas-overlay"></div>
        <div class="minicart-inner-content">
          <div class="minicart-close">
            <i class="lnr lnr-cross"></i>
          </div>
          <div class="minicart-content-box">
            <div class="minicart-item-wrapper">
              <ul>
                <li
                  @click="hideCart"
                  class="minicart-item"
                  v-for="product in $store.getters.getCart.products"
                  :key="product.id"
                >
                  <div class="minicart-thumb">
                    <router-link
                      :to="`/proizvodi/${$store.getters.getProductsCategoriesMap[product.category]}/${product.name}`"
                      ><img
                        :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                        alt="product"
                      />
                    </router-link>
                  </div>

                  <div class="minicart-content">
                    <h3 class="product-name">
                      <router-link
                        :to="`/proizvodi/${$store.getters.getProductsCategoriesMap[product.category]}/${product.name}`"
                      >
                        {{ product.name }}</router-link
                      >
                    </h3>
                    <p>
                      <span class="cart-quantity">{{ product.quantity }} <strong>&times;</strong></span>
                      <span class="cart-price">{{ product.price | priceFormat }} RSD</span>
                    </p>
                  </div>
                  <button class="minicart-remove" @click="onRemoveFromCart(product)">
                    <i class="lnr lnr-cross"></i>
                  </button>
                </li>

                <li class="minicart-item" v-if="$store.getters.getCart.products.length === 0">Vaša korpa je prazna</li>
              </ul>
            </div>

            <div class="minicart-pricing-box">
              <ul>
                <li class="total">
                  <span>Ukupno</span>
                  <span
                    ><strong>{{ $store.getters.getCart.total | priceFormat }} RSD</strong></span
                  >
                </li>
              </ul>
            </div>

            <div class="minicart-button" @click="hideCart">
              <router-link
                to="/korpa"
                :class="{
                  disabled: $store.getters.getCart.products.length === 0,
                }"
                ><i class="fa fa-shopping-cart"></i> Korpa</router-link
              >
              <router-link
                to="/kasa"
                :class="{
                  disabled: $store.getters.getCart.products.length === 0,
                }"
                ><i class="fa fa-share"></i> Kasa</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- offcanvas mini cart end -->

    <!-- Scroll to top start -->
    <div class="scroll-top not-visible">
      <i class="fa fa-angle-up"></i>
    </div>
    <!-- Scroll to Top End -->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Footer",
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(function () {
      /*-------- scroll to top start --------*/
      $(window).on("scroll", function () {
        if ($(this).scrollTop() > 600) {
          $(".scroll-top").removeClass("not-visible");
        } else {
          $(".scroll-top").addClass("not-visible");
        }
      });
      $(".scroll-top").on("click", function () {
        $("html,body").animate(
          {
            scrollTop: 0,
          },
          1000
        );
      });
      /*-------- scroll to top end --------*/
    });
  },
  methods: {
    onRemoveFromCart(product) {
      this.$store.dispatch("removeItemFromCart", product);
    },
    hideCart() {
      $("body").removeClass("fix");
      $(".offcanvas-search-inner, .minicart-inner").removeClass("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-widget-logo {
  img {
    width: 100%;
    max-width: 128px;
    display: block;

    &:last-of-type {
      max-width: 80px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.65;
}
</style>

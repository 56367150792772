<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>{{ selectedProductName }}</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"><i class="fa fa-home"></i></router-link>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link to="/proizvodi">Proizvodi</router-link>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link :to="`/proizvodi/${selectedCategoryName}`">{{ selectedCategoryName }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ selectedProductName }}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- page main wrapper start -->
    <div class="shop-main-wrapper section-space">
      <div class="container">
        <div class="row">
          <!-- product details wrapper start -->
          <div class="col-lg-12 order-1 order-lg-2">
            <!-- product details inner end -->
            <div class="product-details-inner">
              <div class="row">
                <div class="col-lg-5">
                  <VueSlickCarousel
                    ref="lgImg"
                    class="product-large-slider slider-for"
                    v-bind="slickOptionsProduct"
                    :asNavFor="$refs.smImgs"
                    :focusOnSelect="true"
                    v-if="selectedProduct.images.length"
                  >
                    <div
                      class="pro-large-img img-zoom"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>

                    <div
                      class="pro-large-img img-zoom"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>

                    <div
                      class="pro-large-img img-zoom"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>
                  </VueSlickCarousel>

                  <VueSlickCarousel
                    ref="smImgs"
                    class="pro-nav slick-row-10 slick-arrow-style slider-nav"
                    v-bind="slickOptionsProNav"
                    :asNavFor="$refs.lgImg"
                    :focusOnSelect="true"
                    v-if="selectedProduct.images.length"
                  >
                    <div
                      class="pro-nav-thumb"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>

                    <div
                      class="pro-nav-thumb"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>

                    <div
                      class="pro-nav-thumb"
                      v-for="(img, index) in selectedProduct.images"
                      :key="Math.random() + index"
                    >
                      <img :src="img" alt="product-details" />
                    </div>
                  </VueSlickCarousel>
                </div>

                <div class="col-lg-7">
                  <div class="product-details-des">
                    <h3 class="product-name">
                      {{ selectedProduct.name }}
                    </h3>

                    <div class="price-box">
                      <span class="price-regular">{{ selectedProduct.price | priceFormat }} RSD</span>
                    </div>

                    <div class="availability" v-if="selectedProduct.active">
                      <i class="fa fa-check-circle"></i>
                      <span>dostupno</span>
                    </div>
                    <div class="availability not" v-if="!selectedProduct.active">
                      <i class="fa fa-times-circle"></i>
                      <span>nije dostupno</span>
                    </div>

                    <p class="pro-desc" v-html="selectedProduct.description" />

                    <div class="quantity-cart-box d-flex align-items-center">
                      <h5>kol:</h5>
                      <div class="quantity">
                        <div class="pro-qty">
                          <input type="number" min="1" step="1" v-model="productQty" />
                        </div>
                      </div>
                      <div class="action_link">
                        <button
                          :disabled="!selectedProduct.active"
                          class="btn btn-cart2"
                          type="button"
                          @click="putItemInCart(selectedProduct, false)"
                        >
                          <template v-if="!adding">dodaj u korpu</template>
                          <template v-if="adding">dodato</template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- product details inner end -->

            <!-- product details reviews start -->
            <div class="product-details-reviews section-space pb-0">
              <div class="row">
                <div class="col-lg-12">
                  <div class="product-review-info">
                    <ul class="nav review-tab">
                      <li>
                        <a class="" href="#">Opis Proizvoda</a>
                      </li>
                    </ul>
                    <div class="tab-content reviews-tab">
                      <div class="tab-pane fade show active" id="tab_one">
                        <div class="tab-one">
                          <p class="pro-desc" v-html="selectedProduct.description" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- product details reviews end -->
          </div>
          <!-- product details wrapper end -->
        </div>
      </div>
    </div>
    <!-- page main wrapper end -->

    <!-- related product area start -->
    <section class="related-products section-space pt-0" v-if="relatedProducts.length > 0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center">
              <h2>Povezani proizvodi</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 product-carousel-wrapper">
            <VueSlickCarousel
              class="product-carousel--4 slick-row-15 slick-sm-row-10 slick-arrow-style"
              v-bind="slickOptionsRelatedProduct"
            >
              <!-- product single item start -->
              <div class="product-item" v-for="relProduct in relatedProducts" :key="relProduct.id">
                <figure class="product-thumb">
                  <router-link :to="`/proizvodi/${selectedCategoryName}/${relProduct.name}`">
                    <img
                      class="pri-img"
                      :src="relProduct.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                      alt="product"
                    />
                    <img
                      class="sec-img"
                      :src="relProduct.images[1] || 'https://static.thenounproject.com/png/340719-200.png'"
                      alt="product"
                    />
                  </router-link>

                  <div class="box-cart">
                    <button
                      type="button"
                      :disabled="!selectedProduct.active"
                      class="btn btn-cart"
                      @click="putItemInCart(relProduct, true)"
                    >
                      <template v-if="!adding">dodaj u korpu</template>
                      <template v-if="adding">dodato</template>
                    </button>
                  </div>
                </figure>

                <div class="product-caption">
                  <p class="product-name">
                    <router-link :to="`/proizvodi/${selectedCategoryName}/${relProduct.name}`">{{
                      relProduct.name
                    }}</router-link>
                  </p>
                  <div class="price-box">
                    <span class="price-regular">{{ relProduct.price | priceFormat }} RSD</span>
                  </div>
                </div>
              </div>
              <!-- product single item end -->
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </section>
    <!-- related product area end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { productCategoriesCollection, productsCollection } from "../../../firebaseConfig";
import { getDataFromApi } from "../../../shared/getDataFromApi";

export default {
  metaInfo: {
    title: "Ime proizvoda",
  },
  components: { VueSlickCarousel },
  data() {
    return {
      selectedProduct: {
        images: [],
      },
      selectedProductName: this.$route.params.proizvod,
      selectedCategoryName: this.$route.params.kategorija,
      selectedCategoryId: "",
      selectedCategory: {},
      relatedProducts: [],
      productQty: 1,
      adding: false,

      slickOptionsProduct: {
        fade: true,
        arrows: false,
      },
      slickOptionsProNav: {
        asNavFor: this.$refs.lgImg,
        slidesToShow: 4,
        centerMode: true,
        centerPadding: "0",
        focusOnSelect: true,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      slickOptionsRelatedProduct: {
        autoplay: false,
        fade: false,
        speed: 1000,
        slidesToShow: 4,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  watch: {
    $route(to) {
      this.selectedCategoryName = to.params.kategorija;
      this.selectedProductName = to.params.proizvod;
      this.loadProductCategories();
    },
    selectedCategoryId(value) {
      if (value) {
        const data = value.split("?")[0];
        this.loadProduct(data);
      }
    },
  },
  created() {
    this.loadProductCategories();
  },
  mounted() {
    this.$nextTick(function () {
      /* This is hack */
      setTimeout(() => {
        const arrows = document.querySelectorAll(".slick-arrow");
        arrows.forEach((el) => {
          el.innerHTML = "";
          el.style.height = 30 + "px";
        });
      }, 1500);
    });
  },
  methods: {
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection.where("name", "==", this.selectedCategoryName).onSnapshot((querySnapshot) => {
        const formattedApi = getDataFromApi(querySnapshot);
        this.selectedCategoryId = formattedApi[0]["id"] + `?${Date.now()}`;
      });
    },
    loadProduct(categoryId) {
      // realtime updates from our posts collection
      productsCollection
        .where("deleted", "==", false)
        .where("name", "==", this.selectedProductName)
        .onSnapshot((querySnapshot) => {
          let products = getDataFromApi(querySnapshot);

          if (products.length > 1) {
            products = products.filter((el) => el.category !== categoryId);
          }

          this.selectedProduct = products[0];
          this.loadProducts(products[0]);
        });
    },
    loadProducts(selProduct) {
      // realtime updates from our posts collection
      productsCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        const products = getDataFromApi(querySnapshot);
        const relProductsArr = [];

        selProduct.relatedProducts.forEach((el) => {
          const arr = products.filter((elem) => elem.id === el.value);
          relProductsArr.push(arr[0]);
        });

        this.relatedProducts = relProductsArr;
      });
    },
    putItemInCart(item, relProduct = false) {
      if (this.adding || !item.active) {
        return;
      }

      const product = { ...item, quantity: relProduct ? 1 : this.productQty };
      this.$store.dispatch("setInCart", product);
      this.adding = true;

      setTimeout(() => {
        this.adding = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-carousel-wrapper {
  .product-item {
    text-align: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }

    .product-name {
      height: 56px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.slick-track.slick-center {
  height: 118px;
  overflow: hidden;
}
</style>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Admin panel</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">Admin</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Kategorije
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- my account wrapper start -->
    <div class="my-account-wrapper section-space">
      <div class="container-fluid">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- My Account Page Start -->
              <div class="myaccount-page-wrapper">
                <!-- My Account Tab Menu Start -->
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                    <MenuAdmin activePage="kategorije" />
                  </div>
                  <!-- My Account Tab Menu End -->

                  <!-- My Account Tab Content Start -->
                  <div class="col-lg-10 col-md-9">
                    <div class="tab-content">
                      <!-- Single Tab Content Start -->
                      <div class="tab-pane fade show active" role="tabpanel">
                        <div class="myaccount-content">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <p><strong>Kategorije proizvoda</strong></p>
                          </div>
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <input
                              type="text"
                              class="search-field"
                              placeholder="Pretraži kategoriju"
                              v-model="productCategoryFilter.searchedValue"
                            />

                            <div class="recipe-amount">
                              <p>
                                Prikazuje
                                {{ productCategoryFilter.showingItemsFirst }}–{{
                                  productCategoryFilter.totalItems >
                                  productCategoryFilter.showingItemsLast
                                    ? productCategoryFilter.showingItemsLast
                                    : productCategoryFilter.totalItems
                                }}
                                od
                                {{ productCategoryFilter.totalItems }} rezultata
                              </p>
                            </div>

                            <button
                              class="btn btn__bg"
                              type="button"
                              @click="onOpenCategoryModal(null, true)"
                            >
                              Dodaj
                            </button>
                          </div>

                          <div
                            class="myaccount-table table-responsive text-center"
                          >
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th>Ime</th>
                                  <th style="width: 64px">Aktivno</th>
                                  <th style="width: 128px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="category in productCategoryFilter
                                    .categoryPaged[
                                    productCategoryFilter.currentPage
                                  ]"
                                  :key="category.name"
                                >
                                  <td>{{ category.name }}</td>
                                  <td>
                                    <i
                                      class="fa"
                                      :class="[
                                        category.active
                                          ? 'fa-check text-green'
                                          : 'fa-times text-red',
                                      ]"
                                    ></i>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-read mr-3"
                                      type="button"
                                      @click="
                                        onOpenCategoryModal(category, true)
                                      "
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button
                                      class="btn btn-read"
                                      type="button"
                                      @click="
                                        onShowConfirmModal(category, true)
                                      "
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                              <ul class="pagination-box">
                                <li>
                                  <a
                                    class="previous"
                                    @click="
                                      onPageNavigation(
                                        productCategoryFilter.currentPage - 1,
                                        true
                                      )
                                    "
                                    ><i class="lnr lnr-chevron-left"></i
                                  ></a>
                                </li>
                                <li
                                  v-for="page in productCategoryFilter.totalPages"
                                  :key="page"
                                  :class="{
                                    active:
                                      productCategoryFilter.currentPage ===
                                      page,
                                  }"
                                >
                                  <a
                                    @click="onPageNavigation(page, true)"
                                    >{{ page }}</a
                                  >
                                </li>

                                <li>
                                  <a
                                    class="next"
                                    @click="
                                      onPageNavigation(
                                        productCategoryFilter.currentPage + 1,
                                        true
                                      )
                                    "
                                    ><i class="lnr lnr-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                            <!-- end pagination area -->
                          </div>
                        </div>

                        <!-- Recipe table -->
                        <div class="myaccount-content">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <p><strong>Kategorije recepata</strong></p>
                          </div>
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <input
                              type="text"
                              class="search-field"
                              placeholder="Pretraži kategoriju"
                              v-model="recipeCategoryFilter.searchedValue"
                            />

                            <div class="recipe-amount">
                              <p>
                                Prikazuje
                                {{ recipeCategoryFilter.showingItemsFirst }}–{{
                                  recipeCategoryFilter.totalItems >
                                  recipeCategoryFilter.showingItemsLast
                                    ? recipeCategoryFilter.showingItemsLast
                                    : recipeCategoryFilter.totalItems
                                }}
                                od
                                {{ recipeCategoryFilter.totalItems }} rezultata
                              </p>
                            </div>

                            <button
                              class="btn btn__bg"
                              type="button"
                              @click="onOpenCategoryModal(null, false)"
                            >
                              Dodaj
                            </button>
                          </div>

                          <div
                            class="myaccount-table table-responsive text-center"
                          >
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th>Ime</th>
                                  <th style="width: 64px">Aktivno</th>
                                  <th style="width: 128px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="category in recipeCategoryFilter
                                    .categoryPaged[
                                    recipeCategoryFilter.currentPage
                                  ]"
                                  :key="category.name"
                                >
                                  <td>{{ category.name }}</td>
                                  <td>
                                    <i
                                      class="fa"
                                      :class="[
                                        category.active
                                          ? 'fa-check text-green'
                                          : 'fa-times text-red',
                                      ]"
                                    ></i>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-read mr-3"
                                      type="button"
                                      @click="
                                        onOpenCategoryModal(category, false)
                                      "
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button
                                      class="btn btn-read"
                                      type="button"
                                      @click="
                                        onShowConfirmModal(category, false)
                                      "
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                              <ul class="pagination-box">
                                <li>
                                  <a
                                    class="previous"
                                    @click="
                                      onPageNavigation(
                                        recipeCategoryFilter.currentPage - 1,
                                        false
                                      )
                                    "
                                    ><i class="lnr lnr-chevron-left"></i
                                  ></a>
                                </li>
                                <li
                                  v-for="page in recipeCategoryFilter.totalPages"
                                  :key="page"
                                  :class="{
                                    active:
                                      recipeCategoryFilter.currentPage === page,
                                  }"
                                >
                                  <a
                                    @click="onPageNavigation(page, false)"
                                    >{{ page }}</a
                                  >
                                </li>

                                <li>
                                  <a
                                    class="next"
                                    @click="
                                      onPageNavigation(
                                        recipeCategoryFilter.currentPage + 1,
                                        false
                                      )
                                    "
                                    ><i class="lnr lnr-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                            <!-- end pagination area -->
                          </div>
                        </div>
                      </div>
                      <!-- Single Tab Content End -->
                    </div>
                  </div>
                  <!-- My Account Tab Content End -->
                </div>
              </div>
              <!-- My Account Page End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- my account wrapper end -->
    <!-- Product category modal-->
    <b-modal v-model="categoryModalShow" hide-footer>
      <div class="d-block text-center my-3 border-bottom">
        <h3>
          {{
            selectedCategory.id
              ? selectedCategory.name
              : "Dodaj kategoriju proizvoda"
          }}
        </h3>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="name-group" label="Ime kategorije:" label-for="name">
          <b-form-input
            id="name"
            v-model="selectedCategory.name"
            type="text"
            placeholder="Ime kategorije"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-priority"
          label="Prioritet:"
          label-for="priority"
        >
          <b-form-input
            id="priority"
            v-model="selectedCategory.priority"
            placeholder="Prioritet"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="selectedCategory.active"
            name="check-button"
            switch
            size="lg"
          >
            Aktivno
          </b-form-checkbox>
        </b-form-group>

        <div class="d-flex justify-content-end align-items-center">
          <button type="submit" class="btn btn-cart2 mr-3" v-if="!loading">
            Sačuvaj
          </button>
          <button
            type="button"
            disabled
            class="btn btn-cart2 mr-3"
            v-if="loading"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span
            >Sačuvaj
          </button>
          <button type="reset" class="btn btn-cart">Zatvori</button>
        </div>
      </b-form>
    </b-modal>
    <!-- product/category categories overlay -->
    <b-overlay :show="showConfirmModal" no-wrap fixed>
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p class="mb-3">
            <strong id="form-confirm-label">Da li ste sigurni?</strong>
          </p>

          <div class="d-flex">
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              @click="onConfirm"
              v-if="!loading"
            >
              Da, obriši
            </button>
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              disabled
              v-if="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Da, obriši
            </button>

            <button class="btn btn-cart" @click="onCancelConfirmModal">
              Odustani
            </button>
          </div>
        </div>
      </template>
    </b-overlay>
  </main>
  <!-- main wrapper end -->
</template>

<script>
import {
  productCategoriesCollection,
  recipeCategoriesCollection,
} from "../../firebaseConfig";
import { getDataFromApi } from "../../shared/getDataFromApi";

import MenuAdmin from "../../components/MenuAdmin";
export default {
  metaInfo: {
    title: "Admin Dashboard",
  },
  components: {
    MenuAdmin,
  },
  name: "AdminDashboard",
  data() {
    return {
      loading: false,
      selectedCategory: {
        id: null,
        name: "",
        priority: "",
        active: true,
        deleted: false,
      },
      categoryModalShow: false,
      categoriesProducts: [],
      categoriesRecipies: [],
      show: true,
      showConfirmModal: false,
      isProductModal: false,
      recipeCategoryFilter: {
        categoryPaged: {},
        perPage: 5,
        currentPage: 1,
        showingItemsFirst: 1,
        showingItemsLast: 5,
        totalItems: 1,
        totalPages: 1,
        searchedValue: "",
        sortFilter: {
          search: "",
        },
      },
      productCategoryFilter: {
        categoryPaged: {},
        perPage: 5,
        currentPage: 1,
        showingItemsFirst: 1,
        showingItemsLast: 5,
        totalItems: 1,
        totalPages: 1,
        searchedValue: "",
        sortFilter: {
          search: "",
        },
      },
    };
  },
  created() {
    this.loadProductCategories();
    this.loadRecipeCategories();
  },
  watch: {
    "recipeCategoryFilter.searchedValue"(value) {
      this.recipeCategoryFilter.sortFilter["search"] = value;
    },
    "productCategoryFilter.searchedValue"(value) {
      this.productCategoryFilter.sortFilter["search"] = value;
    },
    "recipeCategoryFilter.sortFilter": {
      handler(data) {
        let arr = [...this.categoriesRecipies];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.categoriesRecipies.filter((el) =>
            el.name.toLowerCase().includes(val)
          );
        }
        this.onPagingLogic([...arr], false);
      },
      deep: true,
    },
    "productCategoryFilter.sortFilter": {
      handler(data) {
        let arr = [...this.categoriesProducts];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.categoriesProducts.filter((el) =>
            el.name.toLowerCase().includes(val)
          );
        }
        this.onPagingLogic([...arr], true);
      },
      deep: true,
    },
  },
  methods: {
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection
        .where("deleted", "==", false)
        .onSnapshot((querySnapshot) => {
          this.categoriesProducts = getDataFromApi(querySnapshot);

          this.productCategoryFilter.totalItems = this.categoriesProducts.length;
          this.productCategoryFilter.totalPages = Math.ceil(
            this.categoriesProducts.length / this.productCategoryFilter.perPage
          );

          this.onPagingLogic([...this.categoriesProducts], true);
          this.onPageNavigation(1);
        });
    },
    loadRecipeCategories() {
      recipeCategoriesCollection
        .where("deleted", "==", false)
        .onSnapshot((querySnapshot) => {
          this.categoriesRecipies = getDataFromApi(querySnapshot);

          this.recipeCategoryFilter.totalItems = this.categoriesRecipies.length;
          this.recipeCategoryFilter.totalPages = Math.ceil(
            this.categoriesRecipies.length / this.recipeCategoryFilter.perPage
          );

          this.onPagingLogic([...this.categoriesRecipies], false);
          this.onPageNavigation(1);
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.loading = true;

      const sentData = { ...this.selectedCategory };
      delete sentData.id;
      // updating existing documents
      if (this.selectedCategory.id) {
        const selectedCategory = this.isProductModal
          ? productCategoriesCollection.doc(this.selectedCategory.id)
          : recipeCategoriesCollection.doc(this.selectedCategory.id);

        selectedCategory
          .update(sentData)
          .then(() => {
            this.categoryModalShow = false;
            this.loading = false;
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            this.loading = false;
          });
        // Adding new document into collections
      } else {
        const collection = this.isProductModal
          ? productCategoriesCollection
          : recipeCategoriesCollection;
        collection
          .add(sentData)
          .then(() => {
            this.categoryModalShow = false;
            // cak i bez ovoga se automatski reloaduje
            // Da li ovo treba? Zar se automatski ne updejtuje zbog snahpshota?
            if (this.isProductModal) {
              this.loadProductCategories();
            } else {
              this.loadRecipeCategories();
            }
            this.loading = false;
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            this.loading = false;
          });
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.selectedCategory.id = null;
      this.selectedCategory.name = "";
      this.selectedCategory.active = true;
      this.selectedCategory.priority = null;
      this.selectedCategory.deleted = false;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });

      this.categoryModalShow = false;
      this.isProductModal = false;
    },
    // Da li mozemo da koristimo isti onOpen, posto imamo ista polja za obe kategorije?
    // Imamo isti kod.
    onOpenCategoryModal(selected, isProductModal) {
      this.isProductModal = isProductModal;
      if (selected) {
        this.selectedCategory = { ...selected };
      } else {
        this.selectedCategory.id = null;
        this.selectedCategory.name = "";
        this.selectedCategory.active = true;
        this.selectedCategory.priority = null;
        this.selectedCategory.deleted = false;
      }
      this.categoryModalShow = true;
    },
    onShowConfirmModal(selected, isProductModal) {
      this.selectedCategory = { ...selected };
      this.showConfirmModal = true;
      this.isProductModal = isProductModal;
    },
    onCancelConfirmModal() {
      this.selectedCategory = {
        id: null,
        name: "",
        priority: "",
        active: true,
        deleted: false,
      };
      this.showConfirmModal = false;
      this.isProductModal = false;
    },
    onConfirm() {
      // parametear
      this.loading = true;
      const sentData = {
        ...this.selectedCategory,
        deleted: true,
        active: false,
      };
      delete sentData.id;
      // Based on isProductModel selectedCategory is chosen.
      const selectedCategory = this.isProductModal
        ? productCategoriesCollection.doc(this.selectedCategory.id)
        : recipeCategoriesCollection.doc(this.selectedCategory.id);

      selectedCategory
        .update(sentData)
        .then(() => {
          this.showConfirmModal = false;
          this.loading = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          this.loading = false;
        });

      this.isProductModal = false;
    },
    onPageNavigation(selectedPage, isProductCategory) {
      if (isProductCategory) {
        if (selectedPage === 0) {
          return;
        }
        if (selectedPage > this.productCategoryFilter.totalPages) {
          return;
        }
        this.productCategoryFilter.currentPage = selectedPage;

        this.productCategoryFilter.showingItemsFirst =
          this.productCategoryFilter.currentPage *
            this.productCategoryFilter.perPage -
          this.productCategoryFilter.perPage +
          1;
        this.productCategoryFilter.showingItemsLast =
          this.productCategoryFilter.currentPage *
          this.productCategoryFilter.perPage;
      } else {
        if (selectedPage === 0) {
          return;
        }
        if (selectedPage > this.recipeCategoryFilter.totalPages) {
          return;
        }
        this.recipeCategoryFilter.currentPage = selectedPage;

        this.recipeCategoryFilter.showingItemsFirst =
          this.recipeCategoryFilter.currentPage *
            this.recipeCategoryFilter.perPage -
          this.recipeCategoryFilter.perPage +
          1;
        this.recipeCategoryFilter.showingItemsLast =
          this.recipeCategoryFilter.currentPage *
          this.recipeCategoryFilter.perPage;
      }
    },
    onPagingLogic(editableArr, isProductCategory) {
      // Videti da se refaktorise
      if (isProductCategory) {
        this.productCategoryFilter.totalPages = Math.ceil(
          editableArr.length / this.productCategoryFilter.perPage
        );
        this.productCategoryFilter.totalItems = editableArr.length;

        if (editableArr.length < this.productCategoryFilter.perPage) {
          this.productCategoryFilter.categoryPaged = {
            1: editableArr,
          };
        } else {
          for (
            let index = 1;
            index <= this.productCategoryFilter.totalPages;
            index++
          ) {
            const first = 0;
            const last = this.productCategoryFilter.perPage;
            const arr = editableArr.splice(first, last);
            this.productCategoryFilter.categoryPaged[index] = arr;
          }
        }
      } else {
        this.recipeCategoryFilter.totalPages = Math.ceil(
          editableArr.length / this.recipeCategoryFilter.perPage
        );
        this.recipeCategoryFilter.totalItems = editableArr.length;

        if (editableArr.length < this.recipeCategoryFilter.perPage) {
          this.recipeCategoryFilter.categoryPaged = {
            1: editableArr,
          };
        } else {
          for (
            let index = 1;
            index <= this.recipeCategoryFilter.totalPages;
            index++
          ) {
            const first = 0;
            const last = this.recipeCategoryFilter.perPage;
            const arr = editableArr.splice(first, last);
            this.recipeCategoryFilter.categoryPaged[index] = arr;
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-read {
  &:hover {
    color: #fff !important;
  }
}
</style>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Admin panel</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">Admin</li>
                  <li class="breadcrumb-item active" aria-current="page">Proizvodi</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- my account wrapper start -->
    <div class="my-account-wrapper section-space">
      <div class="container-fluid">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- My Account Page Start -->
              <div class="myaccount-page-wrapper">
                <!-- My Account Tab Menu Start -->
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                    <MenuAdmin activePage="proizvodi" />
                  </div>
                  <!-- My Account Tab Menu End -->

                  <!-- My Account Tab Content Start -->
                  <div class="col-lg-10 col-md-9">
                    <div class="tab-content">
                      <!-- Single Tab Content Start -->
                      <div class="tab-pane fade show active" role="tabpanel">
                        <div class="myaccount-content">
                          <div class="d-flex justify-content-between align-items-center">
                            <input
                              type="text"
                              class="search-field"
                              placeholder="Pretraži proizvode"
                              v-model="searchedValue"
                            />

                            <div class="recipe-amount">
                              <p>
                                Prikazuje {{ showingItemsFirst }}–{{
                                  totalItems > showingItemsLast ? showingItemsLast : totalItems
                                }}
                                od {{ totalItems }} rezultata
                              </p>
                            </div>

                            <button class="btn btn__bg" type="button" @click="onOpenProductModal(null)">Dodaj</button>
                          </div>

                          <div class="myaccount-table table-responsive text-center">
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-left">Ime</th>
                                  <th class="text-left" style="width: 128px">Cena</th>
                                  <th>Kategorija</th>
                                  <th style="width: 64px">Početna</th>
                                  <th style="width: 128px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="product in productsPaged[currentPage]" :key="product.id">
                                  <td class="text-left">
                                    {{ product.name }}
                                  </td>
                                  <td class="text-left">{{ product.price }} RSD</td>
                                  <td>
                                    {{ categoriesMap[product.category] || "NA" }}
                                  </td>
                                  <td>
                                    <i
                                      class="fa"
                                      :class="[product.promotion ? 'fa-check text-green' : 'fa-times text-red']"
                                    ></i>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-read mr-3"
                                      type="button"
                                      @click="onOpenProductModal(product)"
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button class="btn btn-read" type="button" @click="onShowConfirmModal(product)">
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                              <ul class="pagination-box">
                                <li>
                                  <a class="previous" href="#" @click="onPageNavigation(currentPage - 1)"
                                    ><i class="lnr lnr-chevron-left"></i
                                  ></a>
                                </li>
                                <li v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                                  <a href="#" @click="onPageNavigation(page)">{{ page }}</a>
                                </li>

                                <li>
                                  <a class="next" href="#" @click="onPageNavigation(currentPage + 1)"
                                    ><i class="lnr lnr-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                            <!-- end pagination area -->
                          </div>
                        </div>
                      </div>
                      <!-- Single Tab Content End -->
                    </div>
                  </div>
                  <!-- My Account Tab Content End -->
                </div>
              </div>
              <!-- My Account Page End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- my account wrapper end -->

    <b-modal v-model="productsModalShow" hide-footer>
      <div class="d-block text-center my-3 border-bottom">
        <h3>
          {{ selectedProduct.id ? selectedProduct.name : "Dodaj proizvod" }}
        </h3>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="name-group" label="Ime proizvoda:" label-for="name">
          <b-form-input
            id="name"
            v-model="selectedProduct.name"
            type="text"
            placeholder="Ime proizvoda"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-price" label="Cena:" label-for="price">
          <b-form-input
            id="price"
            v-model="selectedProduct.price"
            placeholder="Cena"
            type="number"
            min="1"
            step="0.01"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-category" label="Kategorija:" label-for="category">
          <b-form-select
            id="category"
            v-model="selectedProduct.category"
            :options="categoriesOption"
            required
          ></b-form-select>
        </b-form-group>

        <div class="d-flex justify-content-around">
          <b-form-group>
            <b-form-checkbox v-model="selectedProduct.active" name="check-button" switch size="lg">
              Aktivno
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox v-model="selectedProduct.promotion" name="check-button" switch size="lg">
              Početna
            </b-form-checkbox>
          </b-form-group>
        </div>

        <b-form-group id="description-group" label="Opis proizvoda:" label-for="description">
          <Vueditor ref="editor" id="description" rows="3" max-rows="6" class="vueditor" required />
        </b-form-group>

        <b-form-group id="related-products-group" label="Uvezani proizvodi:" label-for="relatedProducts">
          <multiselect
            v-model="selectedProduct.relatedProducts"
            :options="productOptions"
            :close-on-select="false"
            :multiple="true"
            placeholder="Izaberi proizvod/e"
            label="name"
            track-by="value"
          >
          </multiselect>
        </b-form-group>

        <b-form-group id="sku-group" label="SKU proizvoda:" label-for="sku">
          <b-form-input
            id="sku"
            v-model="selectedProduct.sku"
            type="text"
            placeholder="SKU proizvoda"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-file
            v-model="productImages"
            :state="Boolean(productImages)"
            placeholder="Izaberi sliku ili prevuci preko"
            drop-placeholder="Prevuci sliku ovde..."
            multiple
            accept="image/*"
          ></b-form-file>
          <!-- <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div> -->

          <div class="images-preview-wrapper">
            <div class="row">
              <div v-for="image in productImagesPreview" :key="image" class="col-3">
                <div class="image-div">
                  <button @click="onRemoveSelectedImage(image)" class="icon-btn" type="button">
                    <i class="fa fa-times"></i>
                  </button>
                  <img :src="image.image" />
                  <p class="label text-center text-truncate">
                    {{ image.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>

        <div class="d-flex justify-content-end align-items-center">
          <button type="submit" class="btn btn-cart2 mr-3" v-if="!loading">Sačuvaj</button>
          <button type="button" disabled class="btn btn-cart2 mr-3" v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Sačuvaj
          </button>
          <button type="reset" class="btn btn-cart">Zatvori</button>
        </div>
      </b-form>
    </b-modal>

    <b-overlay :show="showConfirmModal" no-wrap fixed>
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p class="mb-3">
            <strong id="form-confirm-label">Da li ste sigurni?</strong>
          </p>

          <div class="d-flex">
            <button class="btn btn-cart2 mr-3" type="button" @click="onConfirm" v-if="!loading">Da, obriši</button>
            <button class="btn btn-cart2 mr-3" type="button" disabled v-if="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Da, obriši
            </button>

            <button class="btn btn-cart" @click="onCancelConfirmModal">Odustani</button>
          </div>
        </div>
      </template>
    </b-overlay>
  </main>
  <!-- main wrapper end -->
</template>

<script>
import { timeStamp, productCategoriesCollection, productsCollection, storageRef } from "../../firebaseConfig";
import Multiselect from "vue-multiselect";
import { getDataFromApi } from "../../shared/getDataFromApi";

import MenuAdmin from "../../components/MenuAdmin";
export default {
  metaInfo: {
    title: "Admin Proizvodi",
  },
  components: {
    MenuAdmin,
    Multiselect,
  },
  name: "AdminProizvodi",
  data() {
    return {
      loading: false,
      selectedProduct: {
        id: null,
        active: true,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        category: "",
        dateadded: "",
        deleted: false,
        description: "",
        images: [],
        name: "",
        price: "",
        promotion: false,
        sku: "",
        relatedProducts: [],
      },
      productImages: [],
      productImagesPreview: [],
      productsModalShow: false,
      products: [],
      categoriesOption: [],
      categoriesMap: {},
      show: true,
      showConfirmModal: false,
      productOptions: [],
      productsPaged: {},
      perPage: 16,
      currentPage: 1,
      showingItemsFirst: 1,
      showingItemsLast: 16,
      totalItems: 1,
      totalPages: 1,
      searchedValue: "",
      sortFilter: {
        search: "",
      },
    };
  },
  created() {
    this.loadProducts();
    this.loadProductCategories();
  },
  watch: {
    productImages: function (value) {
      if (value) {
        value.forEach((el) => {
          const image = URL.createObjectURL(el);
          this.productImagesPreview.push({
            name: el.name,
            image: image,
            file: el,
          });
        });
      }
    },
    searchedValue(value) {
      this.sortFilter["search"] = value;
    },
    sortFilter: {
      handler(data) {
        let arr = [...this.products];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.products.filter((el) => el.name.toLowerCase().includes(val));
        }

        this.onPagingLogic([...arr]);
        this.onPageNavigation(1);
      },
      deep: true,
    },
  },
  methods: {
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        const categoriesProducts = getDataFromApi(querySnapshot);
        categoriesProducts.forEach((el) => {
          this.categoriesOption.push({ value: el.id, text: el.name });
          this.categoriesMap[el.id] = el.name;
        });
      });
    },
    loadProducts() {
      // realtime updates from our posts collection
      productsCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        this.products = getDataFromApi(querySnapshot);

        this.totalItems = this.products.length;
        this.totalPages = Math.ceil(this.products.length / this.perPage);

        /* Pagination logic */
        this.onPagingLogic([...this.products]);
        this.onPageNavigation(1);
      });
    },
    onSubmit: async function (event) {
      event.preventDefault();

      try {
        this.loading = true;
        const sentData = { ...this.selectedProduct, description: this.$refs.editor.getContent() };
        sentData.dateadded = timeStamp.fromDate(new Date());
        delete sentData.id;

        /* IMAGES LOGIC */
        await this.onUploadImagesToStorage();
        this.productImagesPreview.forEach((file) => {
          if (file.file) {
            sentData.images.push(
              `https://firebasestorage.googleapis.com/v0/b/tupperware-boris.appspot.com/o/producst%2F${file.name}?alt=media`
            );
          }
        });

        if (this.selectedProduct.id) {
          const selectedProduct = productsCollection.doc(this.selectedProduct.id);

          selectedProduct
            .update(sentData)
            .then(() => {
              this.productsModalShow = false;
              this.loading = false;
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
              this.loading = false;
            });
        } else {
          productsCollection
            .add(sentData)
            .then(() => {
              this.productsModalShow = false;
              this.loadProducts();
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.loading = false;
            });
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values

      this.selectedProduct = {
        id: null,
        active: true,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        category: "",
        dateadded: "",
        deleted: false,
        description: "",
        images: [],
        name: "",
        price: "",
        promotion: false,
        sku: "",
        relatedProducts: [],
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });

      this.productsModalShow = false;
    },
    onOpenProductModal(selected) {
      this.productOptions = [];
      if (selected) {
        this.selectedProduct = { ...selected };
        this.products.forEach((el) => {
          // exclude selected product from suggested products
          if (el.id != selected.id) {
            this.productOptions.push({
              value: el.id,
              name: el.name,
            });
          }
        });

        if (selected.images.length > 0) {
          this.productImagesPreview = [];
          selected.images.forEach((el) => {
            this.productImagesPreview.push({
              name: el,
              image: el,
              file: null,
            });
          });
        }
      } else {
        this.products.forEach((el) => {
          this.productOptions.push({
            value: el.id,
            name: el.name,
          });
        });
        this.selectedProduct = {
          id: null,
          active: true,
          addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
          category: "",
          dateadded: "",
          deleted: false,
          description: "",
          images: [],
          name: "",
          price: "",
          promotion: false,
          sku: "",
          relatedProducts: [],
        };

        this.productImagesPreview = [];
      }

      this.productsModalShow = true;

      setTimeout(() => {
        this.$refs.editor.setContent(selected?.description || "");
      }, 700);
    },
    onShowConfirmModal(selected) {
      this.selectedProduct = { ...selected };
      this.showConfirmModal = true;
    },
    onCancelConfirmModal() {
      this.selectedProduct = {
        id: null,
        active: true,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        category: "",
        dateadded: "",
        deleted: false,
        description: "",
        images: [],
        name: "",
        price: "",
        promotion: false,
        sku: "",
        relatedProducts: [],
      };
      this.showConfirmModal = false;
    },
    onConfirm() {
      this.loading = true;

      const sentData = {
        ...this.selectedProduct,
        deleted: true,
        active: false,
      };
      delete sentData.id;
      const selectedProduct = productsCollection.doc(this.selectedProduct.id);

      selectedProduct
        .update(sentData)
        .then(() => {
          this.showConfirmModal = false;
          this.loading = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          this.loading = false;
        });
    },
    onRemoveSelectedImage(image) {
      this.productImagesPreview = this.productImagesPreview.filter((el) => el.image !== image.image);

      if (!image.file) {
        this.selectedProduct.images = this.selectedProduct.images.filter((el) => el !== image.name);
      }
    },
    onUploadImagesToStorage: function () {
      const newImages = this.productImagesPreview.filter((el) => el.file !== null);

      newImages.forEach((file) => {
        const productStorageRef = storageRef.child(`producst/${file.name}`);

        productStorageRef.put(file.file).then(() => {
          productStorageRef.getDownloadURL().then((url) => {
            this.selectedProduct.images.push(url);
          });
        });
      });
    },
    onPageNavigation(selectedPage) {
      if (selectedPage === 0) {
        return;
      }

      if (selectedPage > this.totalPages) {
        return;
      }

      this.currentPage = selectedPage;

      this.showingItemsFirst = this.currentPage * this.perPage - this.perPage + 1;
      this.showingItemsLast = this.currentPage * this.perPage;
    },
    onPagingLogic(editableArr) {
      this.totalPages = Math.ceil(editableArr.length / this.perPage);
      this.totalItems = editableArr.length;

      if (editableArr.length < this.perPage) {
        this.productsPaged = {
          1: editableArr,
        };
      } else {
        for (let index = 1; index <= this.totalPages; index++) {
          const first = 0;
          const last = this.perPage;
          const arr = editableArr.splice(first, last);
          this.productsPaged[index] = arr;
        }
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.btn-read {
  &:hover {
    color: #fff !important;
  }
}

.images-preview-wrapper {
  .image-div {
    padding: 1.5rem 0;
    position: relative;

    .icon-btn {
      position: absolute;
      top: 0.5rem;
      right: -1rem;
      font-size: 18px;
      color: #e74c3c;
      background: #fff;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    image {
      width: 100%;
    }
  }
}
</style>

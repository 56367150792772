<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>{{ selectedRecipeName }}</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"
                      ><i class="fa fa-home"></i
                    ></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link to="/recepti">Recepti</router-link>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link :to="`/recepti/${selectedCategoryName}`">{{
                      selectedCategoryName
                    }}</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ selectedRecipeName }}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- blog main wrapper start -->
    <div class="blog-main-wrapper section-space">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="blog-item-wrapper">
              <!-- blog post item start -->
              <div class="blog-post-item blog-details-post">
                <figure class="blog-thumb img">
                  <img
                    v-if="!selectedRecipe.videoUrl"
                    :src="
                      selectedRecipe.images[0] ||
                      'https://static.thenounproject.com/png/340719-200.png'
                    "
                    alt="Slika recepta"
                    class="img"
                  />

                  <iframe
                    class="img"
                    :src="selectedRecipe.videoUrl"
                    v-if="selectedRecipe.videoUrl"
                    title="text"
                    frameborder="0"
                  ></iframe>
                </figure>
                <div class="blog-content">
                  <h3 class="blog-title blog-title-large">
                    {{ selectedRecipeName }}
                  </h3>
                  <div class="blog-meta">
                    <p>
                      {{ formatDate(selectedRecipe.dateadded) }} |
                      {{ selectedRecipe.addedby }}
                    </p>
                  </div>
                  <div class="entry-summary">
                    <div v-html="selectedRecipe.description" />

                    <div class="blog-share-link">
                      <h5>Podeli:</h5>
                      <div class="blog-social-icon">
                        <ShareNetwork
                          network="facebook"
                          :url="sharedUrl"
                          :title="selectedRecipeName"
                          :description="selectedRecipe.description"
                          :quote="selectedRecipe.description"
                        >
                          <a class="facebook">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </ShareNetwork>

                        <ShareNetwork
                          network="twitter"
                          :url="sharedUrl"
                          :title="selectedRecipeName"
                          :description="selectedRecipe.description"
                          :quote="selectedRecipe.description"
                        >
                          <a class="twitter"><i class="fa fa-twitter"></i></a>
                        </ShareNetwork>

                        <ShareNetwork
                          network="linkedin"
                          :url="sharedUrl"
                          :title="selectedRecipeName"
                          :description="selectedRecipe.description"
                          :quote="selectedRecipe.description"
                        >
                          <a class="linkedin"><i class="fa fa-linkedin"></i></a>
                        </ShareNetwork>

                        <ShareNetwork
                          network="linkedin"
                          :url="sharedUrl"
                          :title="selectedRecipeName"
                          :description="selectedRecipe.description"
                          :quote="selectedRecipe.description"
                        >
                          <a class="instagram"
                            ><i class="fa fa-linkedin"></i
                          ></a>
                        </ShareNetwork>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- blog post item end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog main wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import { recipesCollection } from "../../../firebaseConfig";
import { getDataFromApi } from "../../../shared/getDataFromApi";
import moment from "moment";

export default {
  data() {
    return {
      selectedRecipe: {
        images: [],
      },
      selectedRecipeName: this.$route.params.recept,
      selectedCategoryName: this.$route.params.kategorija,
      recipe: null,
      sharedUrl: window.location.href,
    };
  },
  created() {
    this.loadRecipe();
    console.log(this.$route);
  },
  methods: {
    loadRecipe() {
      recipesCollection
        .where("name", "==", this.selectedRecipeName)
        .onSnapshot((querySnapshot) => {
          const recipes = getDataFromApi(querySnapshot);
          this.selectedRecipe = recipes[0];
        });
    },
    formatDate(value) {
      if (value) {
        return moment(value.toDate()).format("DD-MM-YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  object-fit: cover;
  width: 100%;
  height: 350px;
}
</style>

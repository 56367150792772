<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>O Nama</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"><i class="fa fa-home"></i></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">O nama</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- about wrapper start -->
    <div class="about-us-wrapper section-space">
      <div class="container">
        <div class="row">
          <!-- About Text Start -->
          <div class="col-xl-6 col-lg-6">
            <div class="about-text-wrap">
              <h2>O Tuppereware brendu</h2>
              <p>
                Tupperware® već više od 75 godina izrađuje trajne i praktične proizvode za višekratno korišćenje, koji
                značajno doprinose manjem korišćenju plastike za jednokratnu upotrebu, a time i smanjenju količine
                otpada od plastike i hrane.
              </p>
              <p>I VI možete da odigrate važnu ulogu u ovom procesu.</p>
              <p>
                Najpoznatija svetska firma za proizvodnju posuđa za čuvanje i pripremu hrane, sa ponudom od preko 200
                različitih proizvoda koji pokrivaju potrebe svih segmenata u domaćinstvu.
              </p>
              <p>
                Garancije kvaliteta, potvrde o higijenskoj ispravnosti, prestižna dizajnerska priznanja (RED DOT),
                funkconalnost i jednostavnost upotrebe za sve generacije, lako održavanje - samo su neke od prednosti
                naših proizvoda.
              </p>
              <p>
                Tupperware posuđe, prisutno u celom svetu, omogućava vam da uštedite prostor, vreme i novac. Kupovina
                Tupperware proizvoda je investicija kojom se štedi!
              </p>
            </div>
          </div>
          <!-- About Text End -->
          <!-- About Image Start -->
          <div class="col-xl-5 col-lg-6 ml-auto">
            <div class="about-image-wrap">
              <img alt="o nama" src="../assets/img/about/about.png" />
            </div>
          </div>
          <!-- About Image End -->
          <div class="col-xl-12">
            <div class="about-text-wrap">
              <h2>Zašto odabrati Tupperware proizvode?</h2>
              <p>
                Naša vizija je svakodnevna borba za očuvanje naše planete, života na njoj i zdrave životne sredine, što
                možemo da postignemo samo značajnim smanjenjem organskog i plastičnog otpada.
              </p>
              <p>
                - Tupperware proizvodi zamenjuju plastične proizvode za jednokratnu upotrebu koji nanose nepovratnu
                štetu prirodi i živim bićima u njoj
              </p>
              <p>
                - oblikujemo inovativne proizvode, izrađene od obnovljivih materijala, koje smo nazvali Eco+
                materijalima.
              </p>
              <p>
                Dajte prednost proizvodima za višekratnu upotrebu, a ne onima za jednokratnu. Neka svežina vaših
                namirnica traje duže zahvaljujući pametnim rešenjima za njihovo čuvanje u Tupperware posudama.
              </p>

              <h2>Pridružite nam se u kreiranju bolje budućnosti, jer zajedno možemo da napravimo velike promene.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- about wrapper end -->

    <!-- team area start -->
    <div class="team-area common-bg section-space">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center">
              <h2>O Ikotimu</h2>
            </div>
          </div>
        </div>
        <div class="row mbn-30">
          <div class="col-xl-12">
            <div class="about-text-wrap text-center">
              <p>Ikotim d.o.o je zvanični distributer Tupperware proizvoda u Srbiji.</p>
              <p>
                Naš tim čini zastupnici Olivera i Vladimir Teodorović, I tim lideri Zorica FIlipović, Vesna Bujandrić,
                Maja Koropanovski, Svetlana Grujić, Slavica Zamurović, Jadranka Radomirović I Milica Blagojević.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- team area end -->

    <!-- choosing area start -->
    <div class="choosing-area section-space">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center">
              <h2>Zašto izabrati nas</h2>
            </div>
          </div>
        </div>
        <div class="row mbn-30">
          <div class="col-lg-4">
            <div class="single-choose-item text-center mb-30">
              <i class="fa fa-cutlery"></i>
              <h4>Zdrav materijal</h4>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-choose-item text-center mb-30">
              <i class="fa fa-leaf"></i>
              <h4>Ekološki proizvodi</h4>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-choose-item text-center mb-30">
              <i class="fa fa-truck"></i>
              <h4>Brza isporuka</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- choosing area end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
export default {
  metaInfo: {
    title: "About us",
  },
};
</script>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Katalog</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"
                      ><i class="fa fa-home"></i
                    ></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Katalog
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- checkout main wrapper start -->
    <div class="checkout-page-wrapper section-space">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <a
                class="btn btn__bg"
                :href="src"
                target="_blank"
                download
                title="Skini fajl"
              >
                <i class="fa fa-download"></i>
              </a>
            </div>
          </div>

          <div class="col-12 col-lg-6 offset-lg-3">
            <!-- start pagination area -->
            <div class="paginatoin-area text-center">
              <ul class="pagination-box">
                <li>
                  <a class="previous" @click="onPageNavigation(currentPage - 1)"
                    ><i class="lnr lnr-chevron-left"></i
                  ></a>
                </li>
                <li>{{ currentPage }} / {{ numPages }}</li>

                <li>
                  <a class="next" @click="onPageNavigation(currentPage + 1)"
                    ><i class="lnr lnr-chevron-right"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- end pagination area -->

            <div style="width: 100%">
              <div
                v-if="loadedRatio > 0 && loadedRatio < 1"
                class="loading-div"
                :style="{ width: loadedRatio * 100 + '%' }"
              >
                {{ Math.floor(loadedRatio * 100) }}%
              </div>
              <pdf
                ref="pdf"
                :src="src"
                :page="currentPage"
                @progress="loadedRatio = $event"
                @num-pages="numPages = $event"
                @link-clicked="currentPage = $event"
              ></pdf>
            </div>

            <!-- start pagination area -->
            <div class="paginatoin-area text-center">
              <ul class="pagination-box">
                <li>
                  <a class="previous" @click="onPageNavigation(currentPage - 1)"
                    ><i class="lnr lnr-chevron-left"></i
                  ></a>
                </li>
                <li>{{ currentPage }} / {{ numPages }}</li>

                <li>
                  <a class="next" @click="onPageNavigation(currentPage + 1)"
                    ><i class="lnr lnr-chevron-right"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- end pagination area -->
          </div>
        </div>
      </div>
    </div>
    <!-- checkout main wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import { storageRef } from "../firebaseConfig";
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      src: "",
      loadedRatio: 0,
      currentPage: 1,
      numPages: 1,
    };
  },
  mounted() {
    const catalogStorageRef = storageRef.child(`katalog/Katalog.pdf`);
    catalogStorageRef
      .getDownloadURL()
      .then((url) => {
        this.src = url;
      })
      .catch(() => {
        this.src = "";
      });
  },
  methods: {
    onPageNavigation(selectedPage) {
      if (selectedPage === 0) {
        return;
      }

      if (selectedPage > this.numPages) {
        return;
      }

      this.currentPage = selectedPage;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}

.loading-div {
  color: white;
  text-align: center;
  background: $theme-color;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Plugins
import filters from "@/plugins/filters";
// BootstrapVue
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);
// Vueditor
import Vueditor from "vueditor";
import "vueditor/dist/style/vueditor.min.css";
Vue.use(Vueditor);
// VueSocialSharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);
// FirebaseConfig
const fb = require("./firebaseConfig.js");

Vue.config.productionTip = false;

// handle page reloads
let app = "";
fb.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      filters,
      render: (h) => h(App),
    }).$mount("#app");
  }
});

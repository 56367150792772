<template>
  <div>
    <!-- Start Header Area -->
    <header class="header-area">
      <!-- main header start -->
      <div class="main-header d-none d-lg-block">
        <!-- header top start -->
        <div class="header-top common-bg">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="welcome-message">
                  <p>Dobro došli na Ikotim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- header top end -->

        <div class="header-main-area">
          <!-- header middle area start -->
          <div class="header-middle-area">
            <div class="container">
              <div class="header-middle-inner bdr-bottom">
                <div class="row align-items-center">
                  <!-- start logo area -->
                  <div class="col-lg-6">
                    <div class="logo">
                      <router-link to="/pocetna">
                        <img alt="logo tupperware" src="../assets/img/logo/tupperware-logo.png" />
                        <img alt="log" src="../assets/img/logo/logo.png" />
                      </router-link>
                    </div>
                  </div>
                  <!-- start logo area -->

                  <!-- mini cart area start -->
                  <div class="col-lg-6">
                    <div class="header-configure-wrapper">
                      <div class="header-configure-area">
                        <ul class="nav justify-content-end">
                          <li>
                            <a href="#" class="minicart-btn">
                              <i class="lnr lnr-cart"></i>
                              <div class="notification">
                                {{ $store.getters.getCart.products.length }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- mini cart area end -->
                </div>
              </div>
            </div>
          </div>
          <!-- header middle area end -->

          <!-- main menu area start -->
          <div class="header-meny-style-2 sticky">
            <div class="container">
              <div class="row align-items-center">
                <!-- main menu nav start -->
                <div class="col-lg-9">
                  <div class="main-menu-area">
                    <div class="main-menu menu-style-3 menu-style-4">
                      <!-- main menu navbar start -->
                      <nav class="desktop-menu">
                        <ul>
                          <li>
                            <router-link to="/pocetna">Početna</router-link>
                          </li>
                          <li>
                            <router-link to="/o-nama">O nama</router-link>
                          </li>
                          <li>
                            <router-link to="/proizvodi">Proizvodi <i class="fa fa-angle-down"></i></router-link>
                            <ul class="dropdown">
                              <li v-for="category in categoriesProducts" :key="category.id" @click="onSelect($event)">
                                <router-link :to="`/proizvodi/${category.name}`">{{ category.name }}</router-link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a
                              href="#"
                              @click="
                                (event) => {
                                  event.preventDefault();
                                }
                              "
                            >
                              Katalog <i class="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li @click="onSelect($event)">
                                <router-link :to="`/katalog`">Katalog</router-link>
                              </li>
                              <li @click="onSelect($event)">
                                <router-link :to="`/specijalna-ponuda`">Specijalna ponuda</router-link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <router-link to="/recepti"> Recepti <i class="fa fa-angle-down"></i> </router-link>
                            <ul class="dropdown">
                              <li v-for="category in recipeCategories" :key="category.id" @click="onSelect($event)">
                                <router-link :to="`/recepti/${category.name}`">{{ category.name }}</router-link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <router-link to="/kontakt">Kontakt</router-link>
                          </li>
                        </ul>
                      </nav>
                      <!-- main menu navbar end -->
                    </div>
                  </div>
                </div>
                <!-- main menu nav end -->

                <!-- header search box area start -->
                <div class="col-lg-3">
                  <form class="header-search-box w-100" @submit="event.preventDefault()">
                    <input
                      type="text"
                      class="header-search-field"
                      placeholder="Pretraži proizvode..."
                      v-model="searchedValue"
                    />
                    <button class="header-serch-btn" type="button">
                      <i class="lnr lnr-magnifier"></i>
                    </button>
                  </form>

                  <div class="searchedProductsDiv" v-if="searchedProducts.length > 0">
                    <ul>
                      <li @click="clearInput" v-for="product in searchedProducts" :key="product.id">
                        <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">
                          <img
                            class="image"
                            :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                            alt="product"
                          />
                          {{ product.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- header search box area end -->
              </div>
            </div>
          </div>
          <!-- main menu area end -->
        </div>
      </div>
      <!-- main header start -->

      <!-- mobile header start -->
      <div class="mobile-header d-lg-none d-md-block sticky">
        <!--mobile header top start -->
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="mobile-main-header">
                <div class="mobile-logo">
                  <router-link to="/pocetna">
                    <img alt="log" src="../assets/img/logo/logo.png" />
                  </router-link>
                </div>
                <div class="mobile-menu-toggler">
                  <div class="mini-cart-wrap">
                    <router-link to="/korpa">
                      <i class="lnr lnr-cart"></i>
                    </router-link>
                  </div>
                  <div class="mobile-menu-btn">
                    <div class="off-canvas-btn">
                      <i class="lnr lnr-menu"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- mobile header top start -->
      </div>
      <!-- mobile header end -->
    </header>
    <!-- end Header Area -->

    <!-- off-canvas menu start -->
    <aside class="off-canvas-wrapper">
      <div class="off-canvas-overlay"></div>
      <div class="off-canvas-inner-content">
        <div class="btn-close-off-canvas">
          <i class="lnr lnr-cross"></i>
        </div>

        <div class="off-canvas-inner">
          <!-- search box start -->
          <div class="search-box-offcanvas">
            <input type="text" placeholder="Pretraži proizvode..." v-model="searchedValue" />
            <button class="search-btn" type="button">
              <i class="lnr lnr-magnifier"></i>
            </button>

            <div class="searchedProductsDiv" v-if="searchedProducts.length > 0">
              <ul>
                <li @click="clearInput" v-for="product in searchedProducts" :key="product.id">
                  <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">
                    <img
                      class="image"
                      :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                      alt="product"
                    />
                    {{ product.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- search box end -->

          <!-- mobile menu start -->
          <div class="mobile-navigation">
            <!-- mobile menu navigation start -->
            <nav>
              <ul class="mobile-menu">
                <li><router-link to="/pocetna">Početna</router-link></li>
                <li><router-link to="/o-nama">O nama</router-link></li>
                <li class="menu-item-has-children">
                  <router-link to="/proizvodi">Proizvodi <i class="fa fa-angle-down"></i></router-link>
                  <ul class="megamenu dropdown">
                    <li
                      v-for="category in categoriesProducts"
                      :key="category.id"
                      class="mega-title menu-item-has-children"
                      @click="onSelectMobile()"
                    >
                      <router-link :to="`/proizvodi/${category.name}`">{{ category.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item-has-children">
                  <a> Katalog <i class="fa fa-angle-down"></i> </a>
                  <ul class="megamenu dropdown">
                    <li class="mega-title menu-item-has-children" @click="onSelectMobile()">
                      <router-link :to="`/katalog`">Katalog</router-link>
                    </li>
                    <li class="mega-title menu-item-has-children" @click="onSelectMobile()">
                      <router-link :to="`/specijalna-ponuda`">Specijalna ponuda</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item-has-children">
                  <router-link to="/recepti"> Recepti <i class="fa fa-angle-down"></i> </router-link>
                  <ul class="megamenu dropdown">
                    <li
                      v-for="category in recipeCategories"
                      :key="category.id"
                      class="mega-title menu-item-has-children"
                      @click="onSelectMobile()"
                    >
                      <router-link :to="`/recepti/${category.name}`">{{ category.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li><router-link to="/recepti">Recepti</router-link></li>
                <li><router-link to="/kontak">Kontak</router-link></li>
              </ul>
            </nav>
            <!-- mobile menu navigation end -->
          </div>
          <!-- mobile menu end -->

          <!-- offcanvas widget area start -->
          <div class="offcanvas-widget-area">
            <div class="off-canvas-contact-widget">
              <ul>
                <li>
                  <i class="fa fa-mobile"></i>
                  <a href="tel:+381112437660">+381112437660, +381631749024</a>
                </li>
                <li>
                  <i class="fa fa-envelope-o"></i>
                  <a href="mailto=ikotim.tw@gmail.com">ikotim.tw@gmail.com</a>
                </li>
              </ul>
            </div>
            <div class="off-canvas-social-widget">
              <a href="https://www.facebook.com/TupperwareSrbija1" target="_blank"><i class="fa fa-facebook"></i></a>
              <a href="https://www.instagram.com/tupperwareikotim/?hl=en" target="_blank"
                ><i class="fa fa-instagram"></i
              ></a>
              <a href="https://www.youtube.com/channel/UCs85xR2-taRqGkyIfw6CfLA" target="_blank"
                ><i class="fa fa-youtube-play"></i
              ></a>
            </div>
          </div>
          <!-- offcanvas widget area end -->
        </div>
      </div>
    </aside>
    <!-- off-canvas menu end -->

    <b-alert :show="$store.getters.getIsAdded" dismissible fade @dismiss-count-down="2">
      Dodali ste proizvod u korpu!
    </b-alert>
  </div>
</template>

<script>
import $ from "jquery";
import { productCategoriesCollection, recipeCategoriesCollection, productsCollection } from "../firebaseConfig";
import { getDataFromApi } from "../shared/getDataFromApi";

export default {
  name: "Header",
  data() {
    return {
      isMenuOpen: false,
      categoriesProducts: [],
      categoriesMap: {},
      recipeCategories: [],
      products: [],
      searchedProducts: [],
      searchedValue: "",
    };
  },
  watch: {
    searchedValue(value) {
      let arr = [...this.products];

      if (value) {
        const val = value.toLowerCase(); // for turning off case sensitive
        arr = this.products.filter((el) => el.name.toLowerCase().includes(val));
      } else {
        arr = [];
      }

      this.searchedProducts = arr;
    },
  },
  created() {
    this.loadProductCategories();
    this.loadRecipeCategories();
    this.loadProducts();
  },
  mounted() {
    this.$nextTick(function () {
      /*------ Sticky menu start ------*/
      var $window = $(window);
      $window.on("scroll", function () {
        var scroll = $window.scrollTop();
        if (scroll < 300) {
          $(".sticky").removeClass("is-sticky");
        } else {
          $(".sticky").addClass("is-sticky");
        }
      });
      /*------ Sticky menu end ------*/

      /*-------- Off Canvas Open close start--------*/
      $(".off-canvas-btn").on("click", function () {
        $("body").addClass("fix");
        $(".off-canvas-wrapper").addClass("open");
      });

      $(".btn-close-off-canvas,.off-canvas-overlay").on("click", function () {
        $("body").removeClass("fix");
        $(".off-canvas-wrapper").removeClass("open");
      });
      /*-------- Off Canvas Open close end--------*/

      /*------- offcanvas search form active start -------*/
      $(".offcanvas-btn").on("click", function () {
        $("body").addClass("fix");
        $(".offcanvas-search-inner").addClass("show");
      });

      $(".minicart-btn").on("click", function () {
        $("body").addClass("fix");
        $(".minicart-inner").addClass("show");
      });

      $(".offcanvas-close, .minicart-close,.offcanvas-overlay").on("click", function () {
        $("body").removeClass("fix");
        $(".offcanvas-search-inner, .minicart-inner").removeClass("show");
      });
      /*------- offcanvas search form active end -------*/
    });
  },
  methods: {
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        this.categoriesProducts = getDataFromApi(querySnapshot);
        this.$store.dispatch("loadProductsCategories", this.categoriesProducts);

        this.categoriesProducts.forEach((el) => {
          this.categoriesMap[el.id] = el.name;
        });
      });
    },
    loadProducts() {
      // realtime updates from our posts collection
      productsCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        this.products = getDataFromApi(querySnapshot);
        this.$store.dispatch("loadProducts", this.products);
      });
    },
    loadRecipeCategories() {
      recipeCategoriesCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        this.recipeCategories = getDataFromApi(querySnapshot);
      });
    },
    clearInput() {
      this.searchedValue = "";
    },
    onSelect(e) {
      e.path[2].classList.add("is-hidden-on-click");

      setTimeout(() => {
        e.path[2].classList.remove("is-hidden-on-click");
      }, 750);
    },
    onSelectMobile() {
      $("body").removeClass("fix");
      $(".off-canvas-wrapper").removeClass("open");
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 100%;
    max-width: 256px;
    display: block;

    &:last-of-type {
      max-width: 128px;
    }
  }
}

.searchedProductsDiv {
  position: absolute;
  z-index: 999;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 5px 0px rgba(93, 114, 140, 0.1), 0px 1px 10px 0px rgba(93, 114, 140, 0.08),
    0px 2px 4px -1px rgba(93, 114, 140, 0.16);
  max-height: 256px;
  overflow-y: auto;
  width: 100%;

  ul {
    li {
      margin: 0 0 0.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        display: block;
        color: inherit;

        img {
          width: 24px;
          margin-right: 0.5rem;
        }
      }

      &:hover {
        a {
          color: #ef377f;
        }
      }
    }
  }
}

.alert {
  position: fixed;
  top: 4rem;
  width: 512px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  text-align: center;
}

.mobile-navigation {
  max-height: 100%;
}
</style>

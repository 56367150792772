<template>
  <div id="app">
    <Header v-if="!showAdminHeader" />
    <HeaderAdmin v-if="showAdminHeader" />

    <router-view />

    <Footer v-if="!showAdminHeader" />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import HeaderAdmin from "@/components/HeaderAdmin.vue";

export default {
  data() {
    return {
      showAdminHeader: false,
    };
  },
  components: {
    Header,
    Footer,
    HeaderAdmin,
  },
  mounted() {
    if (this.$route.path.includes("/admin")) {
      this.showAdminHeader = true;
    } else {
      this.showAdminHeader = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.path.includes("/admin")) {
        this.showAdminHeader = true;
      } else {
        this.showAdminHeader = false;
      }
    },
  },
};
</script>

<style lang="scss">
// Custom css
@import "./assets/scss/style.scss";
</style>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- slider area start -->
    <section class="slider-area">
      <VueSlickCarousel
        class="hero-slider-active slick-arrow-style slick-arrow-style_hero slick-dot-style"
        v-bind="slickOptions"
      >
        <!-- single slider item start -->
        <div class="hero-single-slide">
          <div class="hero-slider-item bg-img slide1">
            <div class="hero-slider-content slide-1">
              <img src="~@/assets/img/slider/1.png" alt="novi proizvodi" />
            </div>
          </div>
        </div>
        <!-- single slider item end -->

        <!-- single slider item start -->
        <div class="hero-single-slide">
          <div class="hero-slider-item bg-img slide2">
            <div class="hero-slider-content slide-2">
              <img src="~@/assets/img/slider/2.png" alt="novi proizvodi" />
            </div>
          </div>
        </div>
        <!-- single slider item start -->

        <!-- single slider item start -->
        <div class="hero-single-slide">
          <div class="hero-slider-item bg-img slide3">
            <div class="hero-slider-content slide-3">
              <img src="~@/assets/img/slider/3.png" alt="novi proizvodi" />
            </div>
          </div>
        </div>
        <!-- single slider item start -->
      </VueSlickCarousel>
    </section>
    <!-- slider area end -->

    <!-- our product area start -->
    <section class="our-product section-space pt-5" v-if="products.length > 0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center">
              <h2>Izdvajamo iz ponude</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- product tab container start -->
            <div class="product-container">
              <div class="tab-content">
                <div class="tab-pane fade active show" id="one">
                  <div class="product-carousel-wrapper">
                    <VueSlickCarousel
                      class="product-carousel--5 slick-row-15 slick-sm-row-10 slick-arrow-style"
                      v-bind="slickOptionsProduct"
                    >
                      <!-- product single item start -->
                      <div class="product-item" v-for="product in products" :key="product.id">
                        <figure class="product-thumb">
                          <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">
                            <img
                              class="pri-img"
                              :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                              alt="product"
                            />
                            <img
                              class="sec-img"
                              :src="product.images[1] || 'https://static.thenounproject.com/png/340719-200.png'"
                              alt="product"
                            />
                          </router-link>
                          <div class="product-badge">
                            <div class="product-label new">
                              <span>top</span>
                            </div>
                          </div>
                          <div class="box-cart">
                            <button type="button" class="btn btn-cart" @click="putItemInCart(product)">
                              <template v-if="!adding">dodaj u korpu</template>
                              <template v-if="adding">dodato</template>
                            </button>
                          </div>
                        </figure>
                        <div class="product-caption">
                          <p class="product-name">
                            <a href="product-details.html">{{ product.name }}</a>
                          </p>
                          <div class="price-box">
                            <span class="price-regular">{{ product.price | priceFormat }} RSD</span>
                          </div>
                        </div>
                      </div>
                      <!-- product single item end -->
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
            </div>
            <!-- product tab container end -->
          </div>
        </div>
      </div>
    </section>
    <!-- our product area end -->

    <!-- banner statistics start -->
    <section class="banner-statistics">
      <div class="container">
        <div class="row mbn-30">
          <!-- start store item -->
          <div class="col-md-6">
            <div class="banner-item mb-30">
              <figure class="banner-thumb cursor-pointer" @click="onNavigate('katalog')">
                <img src="../assets/img/banner/banner-left.png" alt="left" />
              </figure>
            </div>
          </div>
          <!-- start store item -->

          <!-- start store item -->
          <div class="col-md-6">
            <div class="banner-item mb-30">
              <figure class="banner-thumb cursor-pointer" @click="onNavigate('specijalna-ponuda')">
                <img src="../assets/img/banner/banner-right.png" alt="right" />
              </figure>
            </div>
          </div>
          <!-- start store item -->
        </div>
      </div>
    </section>
    <!-- banner statistics end -->

    <!-- latest blog area start -->
    <section class="latest-blog section-space pt-5" v-if="recipes.length > 0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title text-center">
              <h2>Recepti</h2>
              <p>Najpopularniji recepti</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <VueSlickCarousel class="blog-carousel-active slick-row-15 slick-arrow-style" v-bind="slickOptionsBlog">
              <!-- blog post item start -->
              <div class="blog-post-item" v-for="recipe in recipes" :key="recipe.id">
                <figure class="blog-thumb">
                  <router-link :to="`/recepti/${recipeCategoriesMap[recipe.category]}/${recipe.name}`">
                    <img
                      v-if="!recipe.videoUrl"
                      :src="recipe.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
                      alt="recipe image"
                    />

                    <iframe id="videoFrame" :src="recipe.videoUrl" v-if="recipe.videoUrl" title="text" frameborder="0">
                    </iframe>
                  </router-link>
                </figure>
                <div class="blog-content">
                  <h3 class="blog-title">
                    <router-link :to="`/recepti/${recipeCategoriesMap[recipe.category]}/${recipe.name}`">
                      {{ recipe.name }}
                    </router-link>
                  </h3>
                  <div class="blog-meta">
                    <p>
                      {{ formatDate(recipe.dateadded) }} |
                      {{ recipe.addedby }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- blog post item end -->
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </section>
    <!-- latest blog area  -->

    <!-- banner statistics start -->
    <section class="banner-statistics section-space pt-2">
      <div class="container">
        <div class="row">
          <!-- start store item -->
          <div class="col-12">
            <div class="banner-inner">
              <div class="banner-item banner-item-height bg-img">
                <figure class="banner-thumb">
                  <img src="../assets/img/banner/banner-wide-bottom.png" alt="wide" />
                </figure>
              </div>
            </div>
          </div>
          <!-- start store item -->
        </div>
      </div>
    </section>
    <!-- banner statistics end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import moment from "moment";

import {
  productCategoriesCollection,
  productsCollection,
  recipeCategoriesCollection,
  recipesCollection,
} from "../firebaseConfig";
import { getDataFromApi } from "../shared/getDataFromApi";

export default {
  metaInfo: {
    title: "Ikotim",
  },
  components: { VueSlickCarousel },
  data() {
    return {
      slickOptions: {
        autoplay: false,
        fade: true,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
            },
          },
        ],
      },
      slickOptionsProduct: {
        autoplay: false,
        fade: false,
        speed: 1000,
        slidesToShow: 5,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsBlog: {
        autoplay: true,
        speed: 1000,
        slidesToShow: 3,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },

      products: [],
      categoriesMap: {},
      recipes: [],
      recipeCategoriesMap: {},
      adding: false,
    };
  },
  created() {
    this.loadProductCategories();
    this.loadProductsOnPromotion();
    this.loadRecipeCategories();
    this.loadRecipesOnPromotion();
  },
  mounted() {
    this.$nextTick(function () {
      /* This is hack */
      setTimeout(() => {
        const arrows = document.querySelectorAll(".slick-arrow");
        arrows.forEach((el) => {
          el.innerHTML = "";
          el.style.height = 30 + "px";
        });

        const dots = document.querySelectorAll(".slick-dots");
        dots.forEach((el) => {
          el.style.bottom = "-32px";
        });
      }, 1500);
    });
  },
  methods: {
    loadProductsOnPromotion() {
      // realtime updates from our posts collection
      productsCollection
        .where("deleted", "==", false)
        .where("promotion", "==", true)
        .onSnapshot((querySnapshot) => {
          this.products = getDataFromApi(querySnapshot);
        });
    },
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        const categoriesProducts = getDataFromApi(querySnapshot);

        categoriesProducts.forEach((el) => {
          this.categoriesMap[el.id] = el.name;
        });
      });
    },
    loadRecipeCategories() {
      // realtime updates from out collection
      recipeCategoriesCollection.where("deleted", "==", false).onSnapshot((querySnapshot) => {
        const recipeCategories = getDataFromApi(querySnapshot);
        // Cisto potvrdi sintaksu sa Tasketom
        recipeCategories.forEach((el) => {
          this.recipeCategoriesMap[el.id] = el.name;
        });
      });
    },
    loadRecipesOnPromotion() {
      recipesCollection.where("promotion", "==", true).onSnapshot((querySnapshot) => {
        this.recipes = getDataFromApi(querySnapshot);
      });
    },
    putItemInCart(item) {
      if (this.adding) {
        return;
      }

      this.$store.dispatch("setInCart", item);
      this.adding = true;

      setTimeout(() => {
        this.adding = false;
      }, 1000);
    },
    formatDate(value) {
      if (value) {
        return moment(value.toDate()).format("DD-MM-YYYY");
      }
    },
    onNavigate(type) {
      this.$router.push(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-thumb {
  height: 250px;
}

.home-links a {
  margin-right: 1rem;
}

.hero-slider-item {
  height: auto !important;

  &.bg-img {
    &.slide1 {
      img {
        width: 100%;
      }
    }

    &.slide2 {
      img {
        width: 100%;
      }
    }

    &.slide3 {
      img {
        width: 100%;
      }
    }
  }
}

.product-carousel-wrapper {
  .product-item {
    text-align: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }

    .product-name {
      height: 56px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.blog-carousel-active {
  .blog-thumb {
    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }

    iframe {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
}

.slider-area {
  margin-bottom: 2rem;
  position: relative;

  .slick-dot-style ul.slick-dots {
    position: absolute;
    bottom: -32px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>

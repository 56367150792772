<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Uspešno!</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"
                      ><i class="fa fa-home"></i
                    ></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Porudžbina
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- checkout main wrapper start -->
    <div class="checkout-page-wrapper section-space">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="successfull-order">
              <i class="fa fa-check-circle" />

              <p>Uspešno ste završili kupovinu!</p>

              <router-link class="btn btn__bg" to="/pocetna"
                >Nazad na početnu</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- main wrapper end -->
</template>

<script>
export default {
  metaInfo: {
    title: "Uspesno!",
  },
};
</script>

<style lang="scss" scoped>
.successfull-order {
  text-align: center;

  i {
    color: #008000;
    font-size: 256px;
  }

  p {
    font-size: 40px;
    font-weight: bold;
    margin: 2rem 0;
  }

  a {
    max-width: 256px;
  }
}
</style>

const getDataFromApi = (querySnapshot) => {
  const arr = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();

    arr.push({ ...data, id: doc.id });
  });

  return arr;
};

export { getDataFromApi };

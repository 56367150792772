import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    productsCategories: [],
    productsCategoriesMap: {},
    cart: {
      products: [],
      total: 0,
    },
    isAdded: false,
  },
  mutations: {
    setProducts(state, data) {
      state.products = data;
    },
    setProductsCategories(state, data) {
      state.productsCategories = data;
    },
    setProductsCategoriesMap(state, data) {
      state.productsCategoriesMap = data;
    },
    addCart(state, data) {
      const stateCart = { ...state.cart, total: 0 };

      const objIndex = stateCart.products.findIndex((el) => el.id === data.id);

      if (objIndex === -1) {
        stateCart.products.push({
          ...data,
          quantity: data.quantity * 1 || 1,
        });
      } else {
        const dataQty = data.quantity * 1 || 1;
        stateCart.products[objIndex] = {
          ...data,
          quantity: stateCart.products[objIndex]["quantity"] + dataQty,
        };
      }

      stateCart.products.forEach((el) => {
        const elPrice = el.price * el.quantity;

        stateCart.total = stateCart.total + elPrice;
      });

      state.cart = stateCart;

      state.isAdded = true;

      setTimeout(() => {
        state.isAdded = false;
      }, 2000);
    },
    removeCart(state, data) {
      const stateCart = { ...state.cart, total: 0 };

      stateCart.products = state.cart.products.filter(
        (el) => el.id !== data.id
      );

      stateCart.products.forEach((el) => {
        const elPrice = el.price * el.quantity;

        stateCart.total = stateCart.total + elPrice;
      });

      state.cart = stateCart;
    },
    updateQuantityCart(state) {
      const stateCart = { ...state.cart, total: 0 };

      stateCart.products.forEach((el) => {
        const elPrice = el.price * el.quantity;

        stateCart.total = stateCart.total + elPrice;
      });

      state.cart = stateCart;
    },
    emptyCart(state) {
      state.cart = {
        products: [],
        total: 0,
      };
    },
  },
  actions: {
    loadProducts({ commit }, data) {
      commit("setProducts", data);
    },
    loadProductsCategories({ commit }, data) {
      commit("setProductsCategories", data);

      const dataMap = {};
      data.forEach((el) => {
        dataMap[el.id] = el.name;
      });

      commit("setProductsCategoriesMap", dataMap);
    },
    setInCart({ commit }, data) {
      commit("addCart", data);
    },
    removeItemFromCart({ commit }, data) {
      commit("removeCart", data);
    },
    updateQuantity({ commit }) {
      commit("updateQuantityCart");
    },
    emptyCart({ commit }) {
      commit("emptyCart");
    },
  },
  getters: {
    getProducts: (state) => state.products,
    getProductsCategories: (state) => state.productsCategories,
    getProductsCategoriesMap: (state) => state.productsCategoriesMap,
    getCart: (state) => state.cart,
    getIsAdded: (state) => state.isAdded,
  },
});

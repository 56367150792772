<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Proizvodi</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"
                      ><i class="fa fa-home"></i
                    ></router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Proizvodi
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- page main wrapper start -->
    <div class="shop-main-wrapper section-space">
      <div class="container">
        <div class="row">
          <!-- sidebar area start -->
          <div class="col-lg-3">
            <aside class="sidebar-wrapper">
              <div class="blog-sidebar">
                <h3 class="title">Pretraga</h3>
                <div class="sidebar-serch-form">
                  <form action="#">
                    <input
                      type="text"
                      class="search-field"
                      placeholder="Pretraži proizvode"
                      v-model="searchedValue"
                    />
                    <button type="submit" class="search-btn">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>

              <!-- single sidebar start -->
              <div class="sidebar-single">
                <h3 class="sidebar-title">Kategorije</h3>
                <div class="sidebar-body">
                  <ul class="shop-categories">
                    <li
                      v-for="category in categoriesProducts"
                      :key="category.id"
                    >
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="category.id"
                          v-model="checkedCategories"
                          :id="'input' + category.id"
                        />
                        <label
                          class="form-check-label"
                          :for="'input' + category.id"
                        >
                          {{ category.name }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- single sidebar end -->
            </aside>
          </div>
          <!-- sidebar area end -->

          <!-- shop main wrapper start -->
          <div class="col-lg-9">
            <div class="shop-product-wrapper">
              <!-- shop product top wrap start -->
              <div class="shop-top-bar">
                <div class="row align-items-center">
                  <div class="col-lg-7 col-md-6 order-2 order-md-1">
                    <div class="top-bar-left">
                      <div class="product-view-mode">
                        <a class="active" href="#" data-target="grid-view"
                          ><i class="fa fa-th"></i
                        ></a>
                        <a href="#" data-target="list-view"
                          ><i class="fa fa-list"></i
                        ></a>
                      </div>
                      <div class="product-amount">
                        <p>
                          Prikazuje {{ showingItemsFirst }}–{{
                            totalItems > showingItemsLast
                              ? showingItemsLast
                              : totalItems
                          }}
                          od {{ totalItems }} rezultata
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-6 order-1 order-md-2">
                    <div class="top-bar-right">
                      <div class="product-short">
                        <p>Sortiraj :</p>
                        <select class="nice-select" v-model="sortOrder">
                          <option value="asc">Ime (A - Z)</option>
                          <option value="desc">Ime (Z - A)</option>
                          <option value="low">Cena (prvo najjeftinije)</option>
                          <option value="high">Cena (prvo najskuplje)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- shop product top wrap start -->

              <!-- product item list wrapper start -->
              <div class="shop-product-wrap grid-view row mbn-30">
                <ProductItem
                  v-for="product in productsPaged[currentPage]"
                  :key="product.id"
                  :product="product"
                  :categoriesMap="categoriesMap"
                  v-on:addToCart="putItemInCart"
                />
              </div>
              <!-- product item list wrapper end -->

              <!-- start pagination area -->
              <div class="paginatoin-area text-center">
                <ul class="pagination-box">
                  <li>
                    <a
                      class="previous"
                      href="#"
                      @click="onPageNavigation(currentPage - 1)"
                      ><i class="lnr lnr-chevron-left"></i
                    ></a>
                  </li>
                  <li
                    v-for="page in totalPages"
                    :key="page"
                    :class="{ active: currentPage === page }"
                  >
                    <a href="#" @click="onPageNavigation(page)">{{ page }}</a>
                  </li>

                  <li>
                    <a
                      class="next"
                      href="#"
                      @click="onPageNavigation(currentPage + 1)"
                      ><i class="lnr lnr-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- end pagination area -->
            </div>
          </div>
          <!-- shop main wrapper end -->
        </div>
      </div>
    </div>
    <!-- page main wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import $ from "jquery";
import {
  productCategoriesCollection,
  productsCollection,
} from "../firebaseConfig";
import { getDataFromApi } from "../shared/getDataFromApi";
import ProductItem from "../components/ProductItem";

export default {
  metaInfo: {
    title: "Proizvodi",
  },
  components: {
    ProductItem,
  },
  data() {
    return {
      categoriesProducts: [],
      categoriesMap: {},
      products: [],
      productsPaged: {},
      sortOrder: "asc",
      perPage: 16,
      currentPage: 1,
      showingItemsFirst: 1,
      showingItemsLast: 16,
      totalItems: 1,
      totalPages: 1,
      searchedValue: "",
      checkedCategories: [],
      sortFilter: {
        sort: "asc",
        search: "",
        categories: [],
      },
    };
  },
  watch: {
    sortOrder(value) {
      this.sortFilter["sort"] = value;
    },
    searchedValue(value) {
      this.sortFilter["search"] = value;
    },
    checkedCategories(values) {
      this.sortFilter["categories"] = values;
    },
    sortFilter: {
      handler(data) {
        let arr = [...this.products];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.products.filter((el) =>
            el.name.toLowerCase().includes(val)
          );
        }

        if (
          !(
            data.categories.length === 0 ||
            data.categories.length === this.categoriesProducts.length
          )
        ) {
          const searchedArr = [];
          data.categories.forEach((el) => {
            arr.forEach((elem) => {
              if (elem.category === el) {
                searchedArr.push(elem);
              }
            });
          });

          arr = searchedArr;
        }

        if (data.sort === "asc") {
          arr.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        }

        if (data.sort === "desc") {
          arr.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }

            // names must be equal
            return 0;
          });
        }

        if (data.sort === "low") {
          arr.sort(function (a, b) {
            return a.price * 1 - b.price * 1;
          });
        }

        if (data.sort === "high") {
          arr.sort(function (a, b) {
            return b.price * 1 - a.price * 1;
          });
        }

        this.onPagingLogic([...arr]);
        this.onPageNavigation(1);
      },
      deep: true,
    },
  },
  created() {
    this.loadProductCategories();
    this.loadProducts();
  },
  mounted() {
    this.$nextTick(function () {
      /*------- product view mode change js start -------*/
      $(".product-view-mode a").on("click", function (e) {
        e.preventDefault();
        var shopProductWrap = $(".shop-product-wrap");
        var viewMode = $(this).data("target");
        $(".product-view-mode a").removeClass("active");
        $(this).addClass("active");
        shopProductWrap.removeClass("grid-view list-view").addClass(viewMode);
      });
      /*------- product view mode change js end -------*/
    });
  },
  methods: {
    loadProductCategories() {
      // realtime updates from our posts collection
      productCategoriesCollection
        .where("deleted", "==", false)
        .onSnapshot((querySnapshot) => {
          this.categoriesProducts = getDataFromApi(querySnapshot);

          this.categoriesProducts.forEach((el) => {
            this.categoriesMap[el.id] = el.name;
          });
        });
    },
    loadProducts() {
      // realtime updates from our posts collection
      productsCollection
        .where("deleted", "==", false)
        .onSnapshot((querySnapshot) => {
          this.products = getDataFromApi(querySnapshot);

          /* Pagination logic */
          this.onPagingLogic([...this.products]);
          this.onPageNavigation(1);
        });
    },
    onPageNavigation(selectedPage) {
      if (selectedPage === 0) {
        return;
      }

      if (selectedPage > this.totalPages) {
        return;
      }

      this.currentPage = selectedPage;

      this.showingItemsFirst =
        this.currentPage * this.perPage - this.perPage + 1;
      this.showingItemsLast = this.currentPage * this.perPage;
    },
    onPagingLogic(editableArr) {
      this.totalPages = Math.ceil(editableArr.length / this.perPage);
      this.totalItems = editableArr.length;

      if (editableArr.length < this.perPage) {
        this.productsPaged = {
          1: editableArr,
        };
      } else {
        for (let index = 1; index <= this.totalPages; index++) {
          const first = 0;
          const last = this.perPage;
          const arr = editableArr.splice(first, last);
          this.productsPaged[index] = arr;
        }
      }
    },
    putItemInCart(item) {
      this.$store.dispatch("setInCart", item);
    },
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";

import Index from "../views/Index.vue";
import ONama from "../views/ONama.vue";
import Proizvodi from "../views/Proizvodi.vue";
import ProizvodiKategorije from "../views/proizvodi/Kategorija.vue";
import Proizvod from "../views/proizvodi/kategorija/Proizvod.vue";
import Recepti from "../views/Recepti.vue";
import ReceptiKategorije from "../views/recepti/Kategorija.vue";
import Recept from "../views/recepti/kategorija/Recept.vue";
import Kasa from "../views/Kasa.vue";
import Korpa from "../views/Korpa.vue";
import Uspesno from "../views/Uspesno.vue";
import Kontakt from "../views/Kontakt.vue";
import Katalog from "../views/Katalog.vue";
import SpecijalnaPonuda from "../views/SpecijalnaPonuda.vue";
import NotFound from "../views/404.vue";
import AdminLogin from "../views/admin/Login.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import AdminKategorije from "../views/admin/Kategorije.vue";
import AdminProizvodi from "../views/admin/Proizvodi.vue";
import AdminRecepti from "../views/admin/Recepti.vue";
import AdminKatalog from "../views/admin/Katalog.vue";

Vue.use(VueRouter);

const routes = [
  { path: "*", name: "404", component: NotFound },

  { path: "/", redirect: "/pocetna" },

  {
    path: "/pocetna",
    name: "Index",
    component: Index,
  },
  {
    path: "/o-nama",
    name: "Onama",
    component: ONama,
  },
  {
    path: "/proizvodi",
    name: "Proizvodi",
    component: Proizvodi,
  },
  {
    path: "/proizvodi/:kategorija",
    name: "ProizvodiKategorija",
    component: ProizvodiKategorije,
  },
  {
    path: "/proizvodi/:kategorija/:proizvod",
    name: "Proizvod",
    component: Proizvod,
  },
  {
    path: "/recepti",
    name: "Recepti",
    component: Recepti,
  },
  {
    path: "/recepti/:kategorija",
    name: "ReceptiKategorije",
    component: ReceptiKategorije,
  },
  {
    path: "/recepti/:kategorija/:recept",
    name: "Recept",
    component: Recept,
  },
  {
    path: "/kasa",
    name: "Kasa",
    component: Kasa,
  },
  {
    path: "/korpa",
    name: "Korpa",
    component: Korpa,
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: Kontakt,
  },
  {
    path: "/uspesno",
    name: "Uspesno",
    component: Uspesno,
  },
  {
    path: "/katalog",
    name: "Katalog",
    component: Katalog,
  },
  {
    path: "/specijalna-ponuda",
    name: "SpecijalnaPonuda",
    component: SpecijalnaPonuda,
  },

  /* ADMIN */
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/kategorije",
    name: "AdminKategorije",
    component: AdminKategorije,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/proizvodi",
    name: "AdminProizvodi",
    component: AdminProizvodi,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/recepti",
    name: "AdminRecepti",
    component: AdminRecepti,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/katalog",
    name: "AdminKatalog",
    component: AdminKatalog,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/admin/login");
  } else {
    next();
  }
});

export default router;

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>login</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    login
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- login register wrapper start -->
    <div class="login-register-wrapper section-space">
      <div class="container">
        <div class="member-area-from-wrap">
          <div class="row">
            <!-- Login Content Start -->
            <div class="col-lg-6 offset-lg-3">
              <div class="login-reg-form-wrap">
                <h2>Prijavi se</h2>
                <form @submit.prevent="submitForm">
                  <div class="single-input-item">
                    <input
                      type="email"
                      placeholder="Email"
                      v-model="email"
                      required
                    />
                  </div>
                  <div class="single-input-item">
                    <input
                      type="password"
                      placeholder="Unesite vašu šifru"
                      v-model="password"
                      required
                    />
                  </div>
                  <div class="single-input-item">
                    <button type="submit" class="btn btn__bg" v-if="!loading">
                      Login
                    </button>

                    <button
                      class="btn btn__bg"
                      type="button"
                      disabled
                      v-if="loading"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- Login Content End -->
          </div>
        </div>
      </div>
    </div>
    <!-- login register wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import firebase from "firebase";

export default {
  metaInfo: {
    title: "Admin Login",
  },
  name: "AdminLogin",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (res) => {
            localStorage.setItem("loggedUser", JSON.stringify(res.user));
            this.$router.push("dashboard");
            this.loading = false;
          },
          (err) => {
            alert("ERROR: " + err.message);
            this.loading = false;
          }
        );
    },
  },
};
</script>

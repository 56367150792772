<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Admin panel</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">Admin</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- my account wrapper start -->
    <div class="my-account-wrapper section-space">
      <div class="container-fluid">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- My Account Page Start -->
              <div class="myaccount-page-wrapper">
                <!-- My Account Tab Menu Start -->
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                    <MenuAdmin activePage="dashboard" />
                  </div>
                  <!-- My Account Tab Menu End -->

                  <!-- My Account Tab Content Start -->
                  <div class="col-lg-10 col-md-9">
                    <div class="tab-content">
                      <!-- Single Tab Content Start -->
                      <div class="tab-pane fade show active" role="tabpanel">
                        <div class="myaccount-content">
                          <div
                            class="row d-flex justify-content-between align-items-center my-2"
                          >
                            <div class="recipe-amount col-2">
                              <input
                                type="text"
                                class="search-field"
                                placeholder="Pretraži porudžbine"
                                v-model="searchedValue"
                              />
                            </div>

                            <div class="recipe-amount col-7 text-center">
                              <p>
                                Prikazuje {{ showingItemsFirst }}–{{
                                  totalItems > showingItemsLast
                                    ? showingItemsLast
                                    : totalItems
                                }}
                                od {{ totalItems }} rezultata
                              </p>
                            </div>

                            <div class="col-1 text-right">
                               <p>Status</p>
                            </div>

                            <div class="col-2 select-div text-right">
                              
                              <select
                                class="orderSelect"
                                v-model="searchedStatus"
                              >
                                <option
                                  v-for="option in orderStatuses"
                                  v-bind:value="option.value"
                                  :key="option.value"
                                >
                                  {{ option.label }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div
                            class="myaccount-table table-responsive text-center"
                          >
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th>Id porudžbine</th>
                                  <th>Poručilac</th>
                                  <th>Status</th>
                                  <th>Datum</th>
                                  <th>Iznos</th>
                                  <th colspan="2">Opcije</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="order in ordersPaged[currentPage]"
                                  :key="order.dateadded"
                                >
                                  <td>
                                    {{ order.id }}
                                  </td>
                                  <td>
                                    {{ order.customer_first_name }}
                                    {{ order.customer_last_name }}
                                  </td>
                                  <td> 
                                    <b-badge class="status" v-if="order.status === 'COMPLETED'" variant="success">Dostavljena</b-badge>
                                    <b-badge class="status" v-if="order.status === 'PENDING'" variant="info">Aktivna</b-badge>
                                  </td>
                                  <td>{{ formatDate(order.orderDate) }}</td>
                                  <td>{{ order.totalPrice.toFixed(2) }} RSD</td>
                                  <td>
                                    <button
                                      class="btn btn__bg"
                                      type="button"
                                      @click="openOrderModal(order)"
                                    >
                                      Pogledaj
                                      <i class="fa fa-edit"></i>
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn__bg bg-success"
                                      type="button"
                                      @click="onShowConfirmModal(order)"
                                      v-if="!order.delivered"
                                    >
                                      Kompletiraj
                                      <i class="fa fa-check"></i>
                                    </button>
                                    <p v-if="order.delivered">Dostavljena</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                              <ul class="pagination-box">
                                <li>
                                  <a
                                    class="previous"
                                    @click="onPageNavigation(currentPage - 1)"
                                    ><i class="lnr lnr-chevron-left"></i
                                  ></a>
                                </li>
                                <li
                                  v-for="page in totalPages"
                                  :key="page"
                                  :class="{ active: currentPage === page }"
                                >
                                  <a @click="onPageNavigation(page)">{{
                                    page
                                  }}</a>
                                </li>

                                <li>
                                  <a
                                    class="next"
                                    @click="onPageNavigation(currentPage + 1)"
                                    ><i class="lnr lnr-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Single Tab Content End -->
                    </div>
                  </div>
                  <!-- My Account Tab Content End -->
                </div>
              </div>
              <!-- My Account Page End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- my account wrapper end -->

    <div>
      <b-modal v-model="showOrderModal" id="modal" hide-footer>
        <div class="d-block text-center my-3 border-bottom modal-header">
          <h5>
            Porudžbina za {{ selectedOrder.customer_first_name }}
            {{ selectedOrder.customer_last_name }}
          </h5>
        </div>
        <div class="container d-block">
          <div class="row border-bottom">
            <div class="col-3 my-2">
              <p><strong>Ime kupca: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_first_name }}
            </div>
            <div class="col-3 my-2">
              <p><strong>Prezime kupca: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_last_name }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 my-2">
              <p><strong>Ulica: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_address }}
            </div>
            <div class="col-3 my-2">
              <p><strong>Grad: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_city }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 my-2">
              <p><strong>Poštanski broj: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_zip }}
            </div>
            <div class="col-3 my-2">
              <p><strong>ID porudžbine: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.id }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 my-2">
              <p><strong>Kontakt telefon: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_phone }}
            </div>
            <div class="col-3 my-2">
              <p><strong>Kontakt email: </strong></p>
            </div>
            <div class="col-3 my-2">
              {{ selectedOrder.customer_email }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 my-2">
              <p><strong>Poruka: </strong></p>
            </div>
            <div class="col-9 my-2">
              {{ selectedOrder.customer_ordernote }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-12 text-center my-3">
              <h6>Status porudžbine:</h6>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-2 my-2">
              <p><strong>Status: </strong></p>
            </div>
            <div v-if="selectedOrder.status === 'COMPLETED'" class="col-2 my-2">
              Dostavljena
            </div>
            <div v-if="selectedOrder.status === 'PENDING'" class="col-2 my-2">
              Aktivna
            </div>
            <div class="col-2 my-2">
              <p><strong>Plaćanje: </strong></p>
            </div>
            <div class="col-2 my-2">
              {{ selectedOrder.paymentMethod }}
            </div>
            <div class="col-2 my-2">
              <p><strong>Dostavljeno: </strong></p>
            </div>
            <div class="col-2 my-2">
              {{ selectedOrder.delivered }}
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-12 text-center my-3">
              <h6>Sadržaj porudžbine:</h6>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-6 my-2">
              <p>Ime proizvoda</p>
            </div>
            <div class="col-3 my-2 text-center">
              <p>SKU</p>
            </div>
            <div class="col-3 my-2 text-center">
              <p>Količina</p>
            </div>
          </div>
          <!-- Creating product rows -->
          <div
            class="row border-bottom"
            v-for="product in orderProducts"
            :key="product.id"
          >
            <div class="col-6 my-2">
              <p>
                <strong>{{ product.name }}</strong>
              </p>
            </div>
            <div class="col-3 my-2 text-center">
              <p>
                <strong> {{ product.sku }} </strong>           
              </p>
            </div>
            <div class="col-3 my-2 text-center">
              <p>
                <strong>{{ product.quantity }}</strong>
              </p>
            </div>
          </div>
          <!-- -->
          <div class="row bottom-border">
            <div class="col-9 my-2">
              <p class="text-right"><strong>Ukupno za naplatu: </strong></p>
            </div>
            <div class="col-3 my-2">
              <p class="text-right">
                {{ selectedOrder.totalPrice.toFixed(2) }} RSD
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <button
            @click="showOrderModal = !showOrderModal"
            class="btn btn-cart"
          >
            Zatvori
          </button>
        </div>
      </b-modal>
    </div>

    <b-overlay :show="showConfirmModal" no-wrap>
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p class="mb-3">
            <strong id="form-confirm-label"
              >Da li ste sigurni da želite da kompletirate porudžbinu?</strong
            >
          </p>

          <div class="d-flex">
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              @click="completeOrder"
              v-if="!loading"
            >
              Da
            </button>
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              disabled
              v-if="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Da
            </button>

            <button class="btn btn-cart" @click="onCancelConfirmModal">
              Odustani
            </button>
          </div>
        </div>
      </template>
    </b-overlay>
  </main>
  <!-- main wrapper end -->
</template>

<script>
import MenuAdmin from "../../components/MenuAdmin";
import { ordersCollection, productsCollection } from "../../firebaseConfig";
import { getDataFromApi } from "../../shared/getDataFromApi";
import moment from "moment";
import { BadgePlugin, BBadge } from 'bootstrap-vue';
export default {
  metaInfo: {
    title: "Admin Dashboard",   
  },
  components: {
    MenuAdmin,
    BadgePlugin,
    BBadge,
  },
  name: "AdminDashboard",
  data() {
    return {
      selectedOrder: {
        customer_first_name: "",
        customer_last_name: "",
        totalPrice: 0,
        status: "",
        products: [],
        delivered: null,
        customer_email: "",
        customer_city: "",
        customer_phone: "",
        customer_zip: "",
        customer_company: "",
        paymentMethod: "",
        customer_address: "",
        customer_ordernote: "",
      },
      orders: [],
      showOrderModal: false,
      productsByNameMap: {},
      productsBySkuMap: {},
      orderProducts: [],
      showConfirmModal: false,
      loading: false,
      ordersPaged: {},
      perPage: 10,
      currentPage: 1,
      showingItemsFirst: 1,
      showingItemsLast: 10,
      totalItems: 1,
      totalPages: 1,
      searchedValue: "",
      sortFilter: {
        search: "",
        status: "ALL",
      },
      searchedStatus: "ALL",
      orderStatuses: [
          { value: "ALL", label: "Sve" },
          { value: "PENDING", label: "Aktivne" },
          { value: "COMPLETED", label: "Dostavljene" }
        ],
    };
  },
  created() {
    this.loadOrders();
    this.loadProducts();
  },
  watch: {
    searchedValue(value) {
      this.sortFilter["search"] = value;
    },
    searchedStatus(value) {
      this.sortFilter["status"] = value;
    },
    sortFilter: {
      handler(data) {
        let arr = [...this.orders];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.orders.filter(
            (el) =>
              // Filter all orders where name, lastname or mail starts with value
              el.customer_first_name.toLowerCase().startsWith(val) ||
              el.customer_last_name.toLowerCase().startsWith(val) ||
              el.customer_email.toLowerCase().startsWith(val) || 
              el.id.toLowerCase().includes(val)
          );

          if(data.status && data.status != "ALL"){
            arr = arr.filter(
              (el) => 

                  el.status === data.status
            )
          }

        } else {
          const val = data.status;
          if (val != "ALL") {
            arr = this.orders.filter(
              (el) =>
                // Filter all orders by status
                el.status === val
            );
          }
        }

        this.onPagingLogic([...arr]);
        this.onPageNavigation(1);
      },
      deep: true,
    },
  },
  methods: {
    loadOrders() {
      ordersCollection.onSnapshot((querySnapshot) => {
        this.orders = getDataFromApi(querySnapshot);

        this.totalItems = this.orders.length;
        this.totalPages = Math.ceil(this.orders.length / this.perPage);

        /* Pagination logic */
        this.onPagingLogic([...this.orders]);
        this.onPageNavigation(1);
      });
    },
    openOrderModal(selected) {
      this.showOrderModal = true;
      this.orderProducts = [];
      this.selectedOrder = { ...selected };

      this.selectedOrder.products.forEach((pr) => {
        this.orderProducts.push({
          name: this.productsByNameMap[pr.productId],
          quantity: pr.quantity,
          sku: this.productsBySkuMap[pr.productId],
        });
      });
    },
    loadProducts() {
      // Collecting all products, need for single orders
      productsCollection.onSnapshot((querySnapshot) => {
        this.products = getDataFromApi(querySnapshot);

        this.products.forEach((product) => {
          this.productsByNameMap[product.id] = product.name;
          this.productsBySkuMap[product.id] = product.sku;
        });
      });
    },
    completeOrder() {
      this.loading = true;

      const sentData = {
        ...this.selectedOrder,
        status: "COMPLETED",
        delivered: true,
      };

      delete sentData.id;
      const orderForComplete = ordersCollection.doc(this.selectedOrder.id);

      orderForComplete
        .update(sentData)
        .then(() => {
          this.loading = false;
          this.showConfirmModal = false;
        })
        .catch((error) => {
          console.error("Error updating order document: ", error);
          this.loading = false;
        });
    },
    onShowConfirmModal(selected) {
      this.selectedOrder = selected;
      this.showConfirmModal = true;
    },
    onCancelConfirmModal() {
      (this.selectedOrder = {
        customer_first_name: "",
        customer_last_name: "",
        totalPrice: 0,
        status: "",
        products: [],
        delivered: null,
        customer_email: "",
        customer_city: "",
        customer_phone: "",
        customer_zip: "",
        customer_company: "",
        paymentMethod: "",
        customer_address: "",
        customer_ordernote: "",
      }),
        (this.showConfirmModal = false);
    },
    onPageNavigation(selectedPage) {
      if (selectedPage === 0) {
        return;
      }

      if (selectedPage > this.totalPages) {
        return;
      }

      this.currentPage = selectedPage;

      this.showingItemsFirst =
        this.currentPage * this.perPage - this.perPage + 1;
      this.showingItemsLast = this.currentPage * this.perPage;
    },
    onPagingLogic(editableArr) {
      this.totalPages = Math.ceil(editableArr.length / this.perPage);
      this.totalItems = editableArr.length;

      if (editableArr.length < this.perPage) {
        this.ordersPaged = {
          1: editableArr,
        };
      } else {
        for (let index = 1; index <= this.totalPages; index++) {
          const first = 0;
          const last = this.perPage;
          const arr = editableArr.splice(first, last);
          this.ordersPaged[index] = arr;
        }
      }
    },
    formatDate(value) {
      if (value) {
        return moment(value.toDate()).format("DD-MM-YYYY HH:MM");
      }
    },
  },
};
</script>
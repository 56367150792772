<template>
  <div class="myaccount-tab-menu nav" role="tablist">
    <router-link
      to="/admin/dashboard"
      :class="{ active: activePage === 'dashboard' }"
      >Dashboard</router-link
    >
    <router-link
      to="/admin/kategorije"
      :class="{ active: activePage === 'kategorije' }"
      >Kategorije</router-link
    >
    <router-link
      to="/admin/proizvodi"
      :class="{ active: activePage === 'proizvodi' }"
      >Proizvodi</router-link
    >
    <router-link
      to="/admin/recepti"
      :class="{ active: activePage === 'recepti' }"
      >Recepti</router-link
    >
    <router-link
      to="/admin/katalog"
      :class="{ active: activePage === 'katalog' }"
      >Katalog</router-link
    >
    <a @click="logout" class="cursor-pointer">Logout</a>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "MenuAdmin",
  props: ["activePage"],
  data() {
    return {};
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("loggedUser");
          this.$router.replace("login");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
</style>

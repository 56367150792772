<template>
  <!-- product single item start -->
  <div class="col-md-4 col-sm-6">
    <!-- product grid start -->
    <div class="product-item mb-3">
      <figure class="product-thumb">
        <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">
          <img
            class="pri-img"
            :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
            alt="product"
          />
          <img
            class="sec-img"
            :src="product.images[1] || 'https://static.thenounproject.com/png/340719-200.png'"
            alt="product"
          />
        </router-link>
        <div class="box-cart">
          <button v-if="product.active" type="button" class="btn btn-cart" @click="onAddToCart(product)">
            <template v-if="!adding">dodaj u korpu</template>
            <template v-if="adding">dodato</template>
          </button>

          <button v-if="!product.active" type="button" class="btn btn-cart">Nije dostupan</button>
        </div>
      </figure>
      <div class="product-caption">
        <p class="product-name">
          <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">{{
            product.name
          }}</router-link>
        </p>
        <div class="price-box">
          <span class="price-regular">{{ product.price | priceFormat }} RSD</span>
        </div>
      </div>
    </div>
    <!-- product grid end -->

    <!-- product list item end -->
    <div class="product-list-item">
      <figure class="product-thumb">
        <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">
          <img
            class="pri-img"
            :src="product.images[0] || 'https://static.thenounproject.com/png/340719-200.png'"
            alt="product"
          />
          <img
            class="sec-img"
            :src="product.images[1] || 'https://static.thenounproject.com/png/340719-200.png'"
            alt="product"
          />
        </router-link>
        <div class="box-cart">
          <button v-if="product.active" type="button" class="btn btn-cart" @click="onAddToCart(product)">
            <template v-if="!adding">dodaj u korpu</template>
            <template v-if="adding">dodato</template>
          </button>

          <button v-if="!product.active" type="button" class="btn btn-cart">Nije dostupan</button>
        </div>
      </figure>
      <div class="product-content-list">
        <h5 class="product-name">
          <router-link :to="`/proizvodi/${categoriesMap[product.category]}/${product.name}`">{{
            product.name
          }}</router-link>
        </h5>
        <div class="price-box">
          <span class="price-regular">{{ product.price | priceFormat }} RSD</span>
        </div>
        <p v-html="product.description" />
      </div>
    </div>
    <!-- product list item end -->
  </div>
  <!-- product single item start -->
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
      default: {},
    },
    categoriesMap: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      adding: false,
    };
  },
  methods: {
    onAddToCart(product) {
      if (this.adding) {
        return;
      }

      this.$emit("addToCart", product);
      this.adding = true;

      setTimeout(() => {
        this.adding = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.product-item {
  .product-name {
    height: 56px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

<template>
  <div>
    <!-- Start Header Area -->
    <header class="header-area">
      <!-- main header start -->
      <div class="main-header d-none d-lg-block">
        <div class="header-main-area">
          <!-- header middle area start -->
          <div class="header-middle-area">
            <div class="container">
              <div class="header-middle-inner bdr-bottom">
                <div class="row align-items-center">
                  <!-- start logo area -->
                  <div class="col-lg-6">
                    <div class="logo">
                      <router-link to="/pocetna">
                        <img alt="log" src="../assets/img/logo/logo.png" />
                      </router-link>
                    </div>
                  </div>
                  <!-- start logo area -->

                  <!-- mini cart area start -->
                  <div class="col-lg-6 text-right">
                    <h2>Admin panel</h2>
                  </div>
                  <!-- mini cart area end -->
                </div>
              </div>
            </div>
          </div>
          <!-- header middle area end -->
        </div>
      </div>
    </header>
    <!-- end Header Area -->
  </div>
</template>

<script>
export default {
  name: "HeaderAdmin",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.logo {
  img {
    width: 100%;
    max-width: 256px;
  }
}
</style>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Admin panel</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">Admin</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Recepti
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- my account wrapper start -->
    <div class="my-account-wrapper section-space">
      <div class="container-fluid">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- My Account Page Start -->
              <div class="myaccount-page-wrapper">
                <!-- My Account Tab Menu Start -->
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                    <MenuAdmin activePage="recepti" />
                  </div>
                  <!-- My Account Tab Menu End -->

                  <!-- My Account Tab Content Start -->
                  <div class="col-lg-10 col-md-9">
                    <div class="tab-content">
                      <!-- Single Tab Content Start -->
                      <div class="tab-pane fade show active" role="tabpanel">
                        <div class="myaccount-content">
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <input
                              type="text"
                              class="search-field"
                              placeholder="Pretraži recepte"
                              v-model="searchedValue"
                            />

                            <div class="recipe-amount">
                              <p>
                                Prikazuje {{ showingItemsFirst }}–{{
                                  totalItems > showingItemsLast
                                    ? showingItemsLast
                                    : totalItems
                                }}
                                od {{ totalItems }} rezultata
                              </p>
                            </div>

                            <button
                              class="btn btn__bg"
                              type="button"
                              @click="onOpenRecipeModal(null)"
                            >
                              Dodaj
                            </button>
                          </div>

                          <div
                            class="myaccount-table table-responsive text-center"
                          >
                            <table class="table table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-left">Ime</th>
                                  <th>Kategorija</th>
                                  <th style="width: 64px">Početna</th>
                                  <th style="width: 128px"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="recipe in recipesPaged[currentPage]"
                                  :key="recipe.id"
                                >
                                  <td class="text-left">
                                    {{ recipe.name }}
                                  </td>
                                  <td>
                                    {{ categoriesMap[recipe.category] || "NA" }}
                                  </td>
                                  <td>
                                    <i
                                      class="fa"
                                      :class="[
                                        recipe.promotion
                                          ? 'fa-check text-green'
                                          : 'fa-times text-red',
                                      ]"
                                    ></i>
                                  </td>
                                  <td>
                                    <button
                                      class="btn btn-read mr-3"
                                      type="button"
                                      @click="onOpenRecipeModal(recipe)"
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button
                                      class="btn btn-read"
                                      type="button"
                                      @click="onShowConfirmModal(recipe)"
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- start pagination area -->
                            <div class="paginatoin-area text-center">
                              <ul class="pagination-box">
                                <li>
                                  <a
                                    class="previous"
                                    href="#"
                                    @click="onPageNavigation(currentPage - 1)"
                                    ><i class="lnr lnr-chevron-left"></i
                                  ></a>
                                </li>
                                <li
                                  v-for="page in totalPages"
                                  :key="page"
                                  :class="{ active: currentPage === page }"
                                >
                                  <a href="#" @click="onPageNavigation(page)">{{
                                    page
                                  }}</a>
                                </li>

                                <li>
                                  <a
                                    class="next"
                                    href="#"
                                    @click="onPageNavigation(currentPage + 1)"
                                    ><i class="lnr lnr-chevron-right"></i
                                  ></a>
                                </li>
                              </ul>
                            </div>
                            <!-- end pagination area -->
                          </div>
                        </div>
                      </div>
                      <!-- Single Tab Content End -->
                    </div>
                  </div>
                  <!-- My Account Tab Content End -->
                </div>
              </div>
              <!-- My Account Page End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- my account wrapper end -->

    <b-modal v-model="recipeModalShow" hide-footer>
      <div class="d-block text-center my-3 border-bottom">
        <h3>
          {{ selectedRecipe.id ? selectedRecipe.name : "Dodaj recept" }}
        </h3>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group id="name-group" label="Naziv recepta:" label-for="name">
          <b-form-input
            id="name"
            v-model="selectedRecipe.name"
            type="text"
            placeholder="Naziv recepta"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-category"
          label="Kategorija:"
          label-for="category"
        >
          <b-form-select
            id="category"
            v-model="selectedRecipe.category"
            :options="categoriesOption"
            required
          ></b-form-select>
        </b-form-group>

        <div class="d-flex justify-content-around">
          <b-form-group>
            <b-form-checkbox
              v-model="selectedRecipe.active"
              name="check-button"
              switch
              size="lg"
            >
              Aktivno
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="selectedRecipe.promotion"
              name="check-button"
              switch
              size="lg"
            >
              Početna
            </b-form-checkbox>
          </b-form-group>
        </div>

        <b-form-group
          id="description-group"
          label="Opis recepta:"
          label-for="description"
        >
          <Vueditor
            ref="editor"
            id="description"
            rows="3"
            max-rows="6"
            class="vueditor"
            required
          />
        </b-form-group>

        <div class="d-flex justify-content-around">
          <b-form-group>
            <b-form-checkbox
              v-model="uploadVideoLink"
              name="check-button"
              switch
              size="lg"
            >
              Link videa umesto slike
            </b-form-checkbox>
          </b-form-group>
          <b-form-group> </b-form-group>
        </div>

        <b-form-group
          id="name-group"
          label="Link recepta:"
          label-for="videoUrl"
          v-if="uploadVideoLink"
        >
          <b-form-input
            id="videoUrl"
            v-model="selectedRecipe.videoUrl"
            type="text"
            placeholder="Link za video recepta"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="!uploadVideoLink">
          <b-form-file
            v-model="recipeImage"
            :state="Boolean(recipeImage)"
            placeholder="Izaberi sliku ili prevuci preko"
            drop-placeholder="Prevuci sliku ovde..."
            accept="image/*"
          ></b-form-file>

          <!-- <div class="mt-3">Selected file: {{ file1 ? file1.name : '' }}</div> -->

          <div class="images-preview-wrapper">
            <div class="row">
              <div
                v-for="image in recipeImagesPreview"
                class="col-3"
                :key="`${image}`"
              >
                <div class="image-div">
                  <button
                    @click="onRemoveSelectedImage(image)"
                    class="icon-btn"
                    type="button"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <img :src="image.image" />
                  <p class="label text-center text-truncate">
                    {{ image.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>

        <div class="d-flex justify-content-end align-items-center">
          <button type="submit" class="btn btn-cart2 mr-3" v-if="!loading">
            Sačuvaj
          </button>
          <button
            type="button"
            disabled
            class="btn btn-cart2 mr-3"
            v-if="loading"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span
            >Sačuvaj
          </button>
          <button type="reset" class="btn btn-cart">Zatvori</button>
        </div>
      </b-form>
    </b-modal>

    <b-overlay :show="showConfirmModal" 
      no-wrap
      fixed>
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p class="mb-3">
            <strong id="form-confirm-label">Da li ste sigurni?</strong>
          </p>

          <div class="d-flex">
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              @click="onConfirm"
              v-if="!loading"
            >
              Da, obriši
            </button>
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              disabled
              v-if="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Da, obriši
            </button>

            <button class="btn btn-cart" @click="onCancelConfirmModal">
              Odustani
            </button>
          </div>
        </div>
      </template>
    </b-overlay>
  </main>
  <!-- main wrapper end -->
</template>

<script>
// const fb = require('../firebaseConfig.js');
import {
  timeStamp,
  storageRef,
  recipeCategoriesCollection,
  recipesCollection,
} from "../../firebaseConfig";
import { getDataFromApi } from "../../shared/getDataFromApi";

import MenuAdmin from "../../components/MenuAdmin";
export default {
  metaInfo: {
    title: "Admin Recepti",
  },
  components: {
    MenuAdmin,
  },
  name: "AdminRecepti",
  data() {
    return {
      loading: false,
      selectedRecipe: {
        id: null,
        name: null,
        description: null,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        active: true,
        promotion: false,
        category: "",
        dateadded: "",
        images: [],
        imageName: "",
        videoUrl: null,
      },
      recipeImage: [],
      recipeImagesPreview: [],
      recipeModalShow: false,
      recipes: [],
      categoriesOption: [],
      categoriesMap: {},
      show: true,
      showConfirmModal: false,
      uploadVideoLink: false,
      recipesPaged: {},
      perPage: 16,
      currentPage: 1,
      showingItemsFirst: 1,
      showingItemsLast: 16,
      totalItems: 1,
      totalPages: 1,
      searchedValue: "",
      sortFilter: {
        search: "",
      },
    };
  },
  created() {
    this.loadRecipes();
    this.loadRecipeCategories();
  },
  watch: {
    recipeImage: function (value) {
      if (value) {
        const image = URL.createObjectURL(value);
        // Clearing arrays since we upload only one picture per recipe
        this.recipeImagesPreview = [];
        this.recipeImagesPreview.push({
          name: value.name,
          image: image,
          file: value,
        });
      }
    },
    searchedValue(value) {
      this.sortFilter["search"] = value;
    },
    sortFilter: {
      handler(data) {
        let arr = [...this.recipes];
        if (data.search) {
          const val = data.search.toLowerCase(); // for turning off case sensitive
          arr = this.recipes.filter((el) =>
            el.name.toLowerCase().includes(val)
          );
        }

        this.onPagingLogic([...arr]);
        this.onPageNavigation(1);
      },
      deep: true,
    },
  },
  methods: {
    loadRecipeCategories() {
      // realtime updates from our posts collection
      recipeCategoriesCollection
        .where("deleted", "==", false)
        .onSnapshot((querySnapshot) => {
          const recipeCategories = getDataFromApi(querySnapshot);
          recipeCategories.forEach((el) => {
            this.categoriesOption.push({ value: el.id, text: el.name });
            this.categoriesMap[el.id] = el.name;
          });
        });
    },
    loadRecipes() {
      // realtime updates from our posts collection
      recipesCollection.onSnapshot((querySnapshot) => {
        this.recipes = getDataFromApi(querySnapshot);

        this.totalItems = this.recipes.length;
        this.totalPages = Math.ceil(this.recipes.length / this.perPage);

        /* Pagination logic */
        this.onPagingLogic([...this.recipes]);
        this.onPageNavigation(1);
      });
    },
    onSubmit: async function (event) {
      event.preventDefault();
      // nije ovde
      try {
        this.loading = true;
        const sentData = {
          ...this.selectedRecipe,
          description: this.$refs.editor.getContent(),
        };
        sentData.dateadded = timeStamp.fromDate(new Date());
        delete sentData.id;
        /* IMAGES LOGIC */
        await this.onUploadImagesToStorage();

        if (this.recipeImagesPreview[0]) {
          const file = this.recipeImagesPreview[0];
          // overriding existing image
          if (file.file) {
            sentData.images = [];
            sentData.images.push(
              `https://firebasestorage.googleapis.com/v0/b/tupperware-boris.appspot.com/o/recipes%2F${file.name}?alt=media`
            );
            // setting name of image, since i need it for storage deletion
            sentData.imageName = file.name;
          }
        }
        if (this.selectedRecipe.id) {
          const selectedRecipe = recipesCollection.doc(this.selectedRecipe.id);
          // check if image or video link is chosen for selected recipe
          if (!this.uploadVideoLink) {
            sentData.videoUrl = null;
            // If existing image is removed, delete image from storage
            // If previously uploaded image is delete, it should be removed from storage
            if (!this.recipeImagesPreview[0] && sentData.imageName) {
              this.deleteImageFromStorage(sentData.imageName);
              sentData.imageName = null;
            }
          } else {
            // In case when video url is chosen, remove image from storage, if exists
            sentData.images = [];
            if (sentData.imageName) {
              this.deleteImageFromStorage(sentData.imageName);
              sentData.imageName = null;
            }
          }

          selectedRecipe
            .update(sentData)
            .then(() => {
              this.recipeModalShow = false;
              this.loading = false;
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
              this.loading = false;
            });
        } else {
          recipesCollection
            .add(sentData)
            .then(() => {
              this.recipeModalShow = false;
              this.loadRecipes();
              this.loading = false;
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
              this.loading = false;
            });
        }

        this.recipeImagesPreview = [];
      } catch (ex) {
        console.log(ex);
      }
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values

      this.selectedRecipe = {
        id: null,
        name: null,
        description: null,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        active: true,
        promotion: false,
        category: "",
        dateadded: "",
        images: [],
        imageName: "",
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });

      this.uploadVideoLink = false;
      this.recipeModalShow = false;
    },
    onOpenRecipeModal(selected) {
      if (selected) {
        this.selectedRecipe = { ...selected };
        // If video link exists, set uploadVideoLink to true
        this.uploadVideoLink = this.selectedRecipe.videoUrl ? true : false;

        if (selected.images.length > 0) {
          this.recipeImagesPreview = [];
          selected.images.forEach((el) => {
            this.recipeImagesPreview.push({
              name: el,
              image: el,
              file: null,
            });
          });
        }
      } else {
        this.selectedRecipe = {
          id: null,
          name: null,
          description: null,
          addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
          active: true,
          promotion: false,
          category: "",
          dateadded: "",
          images: [],
          imageName: "",
        };

        this.recipeImagesPreview = [];
      }

      this.recipeModalShow = true;

      setTimeout(() => {
        this.$refs.editor.setContent(selected?.description || "");
      }, 700);
    },
    onShowConfirmModal(selected) {
      this.selectedRecipe = { ...selected };
      this.showConfirmModal = true;
    },
    onCancelConfirmModal() {
      this.selectedRecipe = {
        id: null,
        name: null,
        description: null,
        addedby: JSON.parse(localStorage.getItem("loggedUser")).email,
        active: true,
        promotion: false,
        category: "",
        dateadded: "",
        images: [],
        imageName: "",
      };

      this.showConfirmModal = false;
    },
    onConfirm() {
      this.loading = true;
      // delete image from storage if exists
      if (this.selectedRecipe.images[0] && this.selectedRecipe.imageName) {
        this.deleteImageFromStorage(this.selectedRecipe.imageName);
      }
      // deleting selected document from collection
      recipesCollection
        .doc(this.selectedRecipe.id)
        .delete()
        .then(() => {
          this.showConfirmModal = false;
          this.loading = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error deleting document: ", error);
          this.loading = false;
        });
    },
    onRemoveSelectedImage(image) {
      this.recipeImagesPreview = this.recipeImagesPreview.filter(
        (el) => el.image !== image.image
      );

      if (!image.file) {
        this.selectedRecipe.images = this.selectedRecipe.images.filter(
          (el) => el !== image.name
        );
      }
    },
    onUploadImagesToStorage: function () {
      const newImages = this.recipeImagesPreview.filter(
        (el) => el.file !== null
      );

      newImages.forEach((file) => {
        const recipeStorageRef = storageRef.child(`recipes/${file.name}`);

        recipeStorageRef.put(file.file).then(() => {
          recipeStorageRef.getDownloadURL().then((url) => {
            this.selectedRecipe.images = [];
            this.selectedRecipe.images.push(url);
          });
        });
      });
    },
    onPageNavigation(selectedPage) {
      if (selectedPage === 0) {
        return;
      }

      if (selectedPage > this.totalPages) {
        return;
      }

      this.currentPage = selectedPage;

      this.showingItemsFirst =
        this.currentPage * this.perPage - this.perPage + 1;
      this.showingItemsLast = this.currentPage * this.perPage;
    },
    onPagingLogic(editableArr) {
      this.totalPages = Math.ceil(editableArr.length / this.perPage);
      this.totalItems = editableArr.length;

      if (editableArr.length < this.perPage) {
        this.recipesPaged = {
          1: editableArr,
        };
      } else {
        for (let index = 1; index <= this.totalPages; index++) {
          const first = 0;
          const last = this.perPage;
          const arr = editableArr.splice(first, last);
          this.recipesPaged[index] = arr;
        }
      }
    },
    deleteImageFromStorage(imageName) {
      const recipeImagePath = imageName;
      const imageRef = storageRef.child(`recipes/` + recipeImagePath);

      imageRef
        .delete()
        .then(() => {
          console.log("Image deleted!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error deleting document: ", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-read {
  &:hover {
    color: #fff !important;
  }
}

.images-preview-wrapper {
  .image-div {
    padding: 1.5rem 0;
    position: relative;

    .icon-btn {
      position: absolute;
      top: 0.5rem;
      right: -1rem;
      font-size: 18px;
      color: #e74c3c;
      background: #fff;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }

    image {
      width: 100%;
    }
  }
}

#description {
  min-height: 450px;
}
</style>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Korpa</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"
                      ><i class="fa fa-home"></i
                    ></router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/proizvodi">Proizvodi</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Korpa
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- cart main wrapper start -->
    <div class="cart-main-wrapper section-space">
      <div class="container">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- Cart Table Area -->
              <div class="cart-table table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="pro-thumbnail">Slika</th>
                      <th class="pro-title">Proizvod</th>
                      <th class="pro-price">Cena</th>
                      <th class="pro-quantity">Količina</th>
                      <th class="pro-subtotal">Ukupno</th>
                      <th class="pro-remove">Ukloni</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="product in $store.getters.getCart.products"
                      :key="product.id"
                    >
                      <td class="pro-thumbnail">
                        <router-link
                          :to="`/proizvodi/${
                            $store.getters.getProductsCategoriesMap[
                              product.category
                            ]
                          }/${product.name}`"
                          ><img
                            class="img-fluid"
                            :src="
                              product.images[0] ||
                              'https://static.thenounproject.com/png/340719-200.png'
                            "
                            alt="product"
                        /></router-link>
                      </td>
                      <td class="pro-title">
                        <router-link
                          :to="`/proizvodi/${
                            $store.getters.getProductsCategoriesMap[
                              product.category
                            ]
                          }/${product.name}`"
                          >{{ product.name }}</router-link
                        >
                      </td>
                      <td class="pro-price">
                        <span>{{ product.price | priceFormat }} RSD</span>
                      </td>
                      <td class="pro-quantity">
                        <div class="pro-qty">
                          <input
                            type="number"
                            step="1"
                            min="1"
                            v-model="product.quantity"
                            @change="onUpadeQty(product)"
                          />
                        </div>
                      </td>
                      <td class="pro-subtotal">
                        <span
                          >{{
                            (product.price * 1 * product.quantity) | priceFormat
                          }}
                          RSD</span
                        >
                      </td>
                      <td class="pro-remove">
                        <button
                          class="minicart-remove"
                          @click="onRemoveFromCart(product)"
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </td>
                    </tr>

                    <tr v-if="$store.getters.getCart.products.length === 0">
                      <td class="pro-thumbnail" colspan="6">
                        Vaša korpa je prazna
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 ml-auto">
              <!-- Cart Calculation Area -->
              <div class="cart-calculator-wrapper">
                <div class="cart-calculate-items">
                  <h3>Ukupno</h3>
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <td>Cena</td>
                        <td>
                          {{ $store.getters.getCart.total | priceFormat }} RSD
                        </td>
                      </tr>
                      <tr>
                        <td>Dostava</td>
                        <td>
                          {{
                            $store.getters.getCart.total > 5999
                              ? "0,00"
                              : "390,00"
                          }}
                        </td>
                      </tr>
                      <tr class="total">
                        <td>Ukupno</td>
                        <td class="total-amount">
                          {{
                            ($store.getters.getCart.total < 6000
                              ? $store.getters.getCart.total + 390
                              : $store.getters.getCart.total) | priceFormat
                          }}
                          RSD
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <router-link
                  to="/kasa"
                  class="btn btn__bg d-block"
                  :class="{
                    disabled: $store.getters.getCart.products.length === 0,
                  }"
                  >Idi na kasu</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- cart main wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
export default {
  metaInfo: {
    title: "Korpa",
  },
  methods: {
    onRemoveFromCart(product) {
      this.$store.dispatch("removeItemFromCart", product);
    },
    onUpadeQty() {
      this.$store.dispatch("updateQuantity");
    },
  },
};
</script>

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Admin panel</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                  </li>
                  <li class="breadcrumb-item" aria-current="page">Admin</li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Katalog
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- my account wrapper start -->
    <div class="my-account-wrapper section-space">
      <div class="container-fluid">
        <div class="section-bg-color">
          <div class="row">
            <div class="col-lg-12">
              <!-- My Account Page Start -->
              <div class="myaccount-page-wrapper">
                <!-- My Account Tab Menu Start -->
                <div class="row">
                  <div class="col-lg-2 col-md-3">
                    <MenuAdmin activePage="katalog" />
                  </div>
                  <!-- My Account Tab Menu End -->

                  <!-- My Account Tab Content Start -->
                  <div class="col-lg-10 col-md-9">
                    <div class="row">
                      <div class="col-6">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <h3>Katalog</h3>

                          <button
                            class="btn btn__bg"
                            type="button"
                            @click="onOpenModal(true)"
                          >
                            Dodaj
                          </button>
                        </div>

                        <div
                          class="d-flex justify-content-between align-items-center mt-5"
                          v-if="catalogFileUrl"
                        >
                          <p>
                            Ime fajla:
                            <a :href="catalogFileUrl" target="_blank"
                              ><b>Katalog.pdf</b></a
                            >
                          </p>

                          <button
                            class="btn btn-read"
                            type="button"
                            @click="onShowConfirmModal(true)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>

                        <div
                          class="d-flex justify-content-between align-items-center mt-5"
                          v-if="!catalogFileUrl"
                        >
                          <p>Nema fajla za katalog</p>
                        </div>
                      </div>

                      <div class="col-6">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <h3>Specijalna ponuda</h3>

                          <button
                            class="btn btn__bg"
                            type="button"
                            @click="onOpenModal(false)"
                          >
                            Dodaj
                          </button>
                        </div>

                        <div
                          class="d-flex justify-content-between align-items-center mt-5"
                          v-if="specialOfferCatalogUrl"
                        >
                          <p>
                            Ime fajla:
                            <a :href="specialOfferCatalogUrl" target="_blank"
                              ><b>Specijalna_ponuda.pdf</b></a
                            >
                          </p>

                          <button
                            class="btn btn-read"
                            type="button"
                            @click="onShowConfirmModal(false)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                        <div
                          class="d-flex justify-content-between align-items-center mt-5"
                          v-if="!specialOfferCatalogUrl"
                        >
                          <p>Nema fajla za specijalnu ponudu</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- My Account Tab Content End -->
                </div>
              </div>
              <!-- My Account Page End -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- my account wrapper end -->
    <!-- Product category modal-->
    <b-modal v-model="modalShow" hide-footer>
      <div class="d-block text-center my-3 border-bottom">
        <h3>
          {{
            isCatalogModal ? "Dodaj novi katalog" : "Dodaj specijalnu ponudu"
          }}
        </h3>
      </div>

      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group>
          <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Izaberi pdf ili prevuci preko"
            drop-placeholder="Prevuci pdf ovde..."
            accept="application/pdf*"
          ></b-form-file>
        </b-form-group>

        <div class="d-flex justify-content-end align-items-center">
          <button type="submit" class="btn btn-cart2 mr-3" v-if="!loading">
            Sačuvaj
          </button>
          <button
            type="button"
            disabled
            class="btn btn-cart2 mr-3"
            v-if="loading"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span
            >Sačuvaj
          </button>
          <button type="reset" class="btn btn-cart">Zatvori</button>
        </div>
      </b-form>
    </b-modal>

    <b-overlay :show="showConfirmModal" no-wrap>
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p class="mb-3">
            <strong id="form-confirm-label">Da li ste sigurni?</strong>
          </p>

          <div class="d-flex">
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              @click="onConfirm"
              v-if="!loading"
            >
              Da, obriši
            </button>
            <button
              class="btn btn-cart2 mr-3"
              type="button"
              disabled
              v-if="loading"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Da, obriši
            </button>

            <button class="btn btn-cart" @click="onCancelConfirmModal">
              Odustani
            </button>
          </div>
        </div>
      </template>
    </b-overlay>
  </main>
  <!-- main wrapper end -->
</template>

<script>
import { storageRef } from "../../firebaseConfig";
import MenuAdmin from "../../components/MenuAdmin";

export default {
  components: {
    MenuAdmin,
  },
  name: "AdminKatalog",
  data() {
    return {
      loading: false,
      modalShow: false,
      show: true,
      showConfirmModal: false,
      isCatalogModal: false,
      file: null,
      catalogFileUrl: null,
      specialOfferCatalogUrl: null,
    };
  },
  mounted() {
    const catalogStorageRef = storageRef.child(`katalog/Katalog.pdf`);
    catalogStorageRef
      .getDownloadURL()
      .then((url) => {
        this.catalogFileUrl = url;
      })
      .catch(() => {
        this.catalogFileUrl = null;
      });

    const specialStorageRef = storageRef.child(`katalog/Specijalna_ponuda.pdf`);
    specialStorageRef
      .getDownloadURL()
      .then((url) => {
        this.specialOfferCatalogUrl = url;
      })
      .catch(() => {
        this.specialOfferCatalogUrl = null;
      });
  },
  methods: {
    onSubmit: function (event) {
      event.preventDefault();
      this.loading = true;

      if (this.isCatalogModal) {
        Object.defineProperty(this.file, "name", {
          writable: true,
          value: "Katalog.pdf",
        });
      } else {
        Object.defineProperty(this.file, "name", {
          writable: true,
          value: "Specijalna_ponuda.pdf",
        });
      }

      const fileStorageRef = storageRef.child(`katalog/${this.file.name}`);

      fileStorageRef
        .put(this.file)
        .then(() => {
          fileStorageRef
            .getDownloadURL()
            .then((url) => {
              this.loading = false;
              this.modalShow = false;

              if (this.isCatalogModal) {
                this.catalogFileUrl = url;
              } else {
                this.specialOfferCatalogUrl = url;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form value

      this.file = null;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });

      this.modalShow = false;
    },
    onOpenModal(type) {
      this.modalShow = true;
      this.isCatalogModal = type;
    },
    onShowConfirmModal(type) {
      this.isCatalogModal = type;
      this.showConfirmModal = true;
    },
    onCancelConfirmModal() {
      this.showConfirmModal = false;
    },
    onConfirm() {
      this.loading = true;

      let fileRef = storageRef.child(`katalog/Specijalna_ponuda.pdf`);

      if (this.isCatalogModal) {
        fileRef = storageRef.child(`katalog/Katalog.pdf`);
      }

      fileRef
        .delete()
        .then(() => {
          this.onCancelConfirmModal();
          this.loading = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error deleting document: ", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-read {
  &:hover {
    color: #fff !important;
  }
}
</style>

<template>
  <!-- product single item start -->
  <div class="col-md-4 col-sm-6">
    <!-- product grid start -->
    <div class="product-item mb-3">
      <figure class="product-thumb">
        <router-link
          :to="`/recepti/${categoriesMap[recipe.category]}/${recipe.name}`"
        >
          <img
            v-if="!recipe.videoUrl"
            class="img"
            :src="
              recipe.images[0] ||
              'https://static.thenounproject.com/png/340719-200.png'
            "
            alt="recipe"
          />

          <iframe
            id="videoFrame"
            :src="recipe.videoUrl"
            v-if="recipe.videoUrl"
            title="text"
            frameborder="0"
          ></iframe>
        </router-link>
      </figure>
      <div class="product-caption">
        <!-- Treba posebna klasa za ime recepta -->
        <p class="product-name">
          <router-link
            :to="`/recepti/${categoriesMap[recipe.category]}/${recipe.name}`"
            >{{ recipe.name }}</router-link
          >
        </p>
      </div>
    </div>
    <!-- product grid end -->
    <!-- product list item end -->
    <div class="product-list-item">
      <figure class="product-thumb">
        <router-link
          :to="`/recepti/${categoriesMap[recipe.category]}/${recipe.name}`"
        >
          <img
            v-if="!recipe.videoUrl"
            class="img"
            :src="
              recipe.images[0] ||
              'https://static.thenounproject.com/png/340719-200.png'
            "
            alt="recipe"
          />

          <iframe
            id="videoFrame"
            :src="recipe.videoUrl"
            v-if="recipe.videoUrl"
            title="text"
            frameborder="0"
          ></iframe>
        </router-link>
      </figure>
      <div class="product-content-list">
        <h5 class="product-name">
          <router-link
            :to="`/recepti/${categoriesMap[recipe.category]}/${recipe.name}`"
            >{{ recipe.name }}</router-link
          >
        </h5>
        <p v-html="recipe.description" />
      </div>
    </div>
    <!-- product list item end -->
  </div>
  <!-- product single item start -->
</template>

<script>
export default {
  name: "RecipeItem",
  props: {
    recipe: {
      type: Object,
      required: true,
      default: {},
    },
    categoriesMap: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    return {
      videoUrl: null,
    };
  },
  methods: {},
};
// Sta cemo da radimo sa videom na stranici kategorije?
</script>

<style scoped lang="scss">
#videoFrame {
  height: 250px;
  width: 100%;
}

img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.recipe-item {
  .recipe-name {
    height: 56px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

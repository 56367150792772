import firebase from "firebase";
import "firebase/firestore";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDona-GDL_JPadpla44cgA4IpoYuCrLA4I",
  authDomain: "tupperware-boris.firebaseapp.com",
  databaseURL: "https://tupperware-boris.firebaseio.com",
  projectId: "tupperware-boris",
  storageBucket: "tupperware-boris.appspot.com",
  messagingSenderId: "748555725814",
  appId: "1:748555725814:web:98362c9e1a073662b8b5d7",
  measurementId: "G-J455MDX33T",
};
firebase.initializeApp(config);
firebase.analytics();

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;
const timeStamp = firebase.firestore.Timestamp;

// firebase collections
const productCategoriesCollection = db.collection("productcategories");
const productsCollection = db.collection("products");
const recipeCategoriesCollection = db.collection("recipecategories");
const recipesCollection = db.collection("recipe");
const ordersCollection = db.collection("orders");

// firebase storages
const storageRef = firebase.storage().ref();

export {
  db,
  auth,
  currentUser,
  timeStamp,
  productCategoriesCollection,
  productsCollection,
  storageRef,
  recipeCategoriesCollection,
  recipesCollection,
  ordersCollection,
};

<template>
  <!-- main wrapper start -->
  <main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area common-bg">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <h1>Kasa</h1>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/pocetna"><i class="fa fa-home"></i></router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/proizvodi">Proizvodi</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Kasa</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- checkout main wrapper start -->
    <div class="checkout-page-wrapper section-space">
      <div class="container">
        <form @submit.prevent="submitOrder" id="submitOrderForm">
          <div class="row">
            <!-- Checkout Billing Details -->
            <div class="col-lg-6">
              <div class="checkout-billing-details-wrap">
                <h2>Vaši podaci</h2>
                <div class="billing-form-wrap">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="single-input-item">
                        <label for="f_name" class="required">Ime</label>
                        <input
                          type="text"
                          id="f_name"
                          placeholder="Ime"
                          name="customer_first_name"
                          v-model="formData.customer_first_name"
                          required
                          oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                          oninput="setCustomValidity('')"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="single-input-item">
                        <label for="l_name" class="required">Prezime</label>
                        <input
                          type="text"
                          id="l_name"
                          placeholder="Prezime"
                          name="customer_last_name"
                          v-model="formData.customer_last_name"
                          required
                          oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                          oninput="setCustomValidity('')"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="single-input-item">
                    <label for="email" class="required">Email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      name="customer_email"
                      v-model="formData.customer_email"
                      required
                      oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                      oninput="setCustomValidity('')"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="phone" class="required">Telefon</label>
                    <input
                      type="text"
                      id="phone"
                      placeholder="Telefon"
                      name="customer_phone"
                      v-model="formData.customer_phone"
                      required
                      oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                      oninput="setCustomValidity('')"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="com-name">Kompanija</label>
                    <input
                      type="text"
                      id="com-name"
                      placeholder="Kompanija"
                      name="customer_company"
                      v-model="formData.customer_company"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="street-address" class="required mt-20">Adresa</label>
                    <input
                      type="text"
                      id="street-address"
                      placeholder="Adresa"
                      required
                      oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                      oninput="setCustomValidity('')"
                      name="customer_address"
                      v-model="formData.customer_address"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="town" class="required">Grad</label>
                    <input
                      type="text"
                      id="town"
                      placeholder="Grad"
                      required
                      oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                      oninput="setCustomValidity('')"
                      name="customer_city"
                      v-model="formData.customer_city"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="postcode" class="required">Poštanski broj</label>
                    <input
                      type="text"
                      id="postcode"
                      placeholder="Poštanski broj"
                      required
                      oninvalid="this.setCustomValidity('Ovo polje je obavezno')"
                      oninput="setCustomValidity('')"
                      name="customer_zip"
                      v-model="formData.customer_zip"
                    />
                  </div>

                  <div class="single-input-item">
                    <label for="ordernote">Napomena</label>
                    <textarea
                      id="ordernote"
                      cols="30"
                      rows="3"
                      placeholder="Napomena prilikom naručivanja"
                      name="customer_ordernote"
                      v-model="formData.customer_ordernote"
                    ></textarea>
                  </div>

                  <input id="orderId" type="hidden" name="order_id" value="" />
                </div>
              </div>
            </div>

            <!-- Order Summary Details -->
            <div class="col-lg-6">
              <div class="order-summary-details">
                <h2>Vaša porudžbina</h2>
                <div class="order-summary-content">
                  <!-- Order Summary Table -->
                  <div class="order-summary-table table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Proizvod</th>
                          <th>Ukupno</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="product in $store.getters.getCart.products" :key="product.id">
                          <td>
                            {{ product.name }}
                            <strong> × {{ product.quantity }}</strong>
                          </td>
                          <td>{{ product.price | priceFormat }} RSD</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td><b>Ukupno</b></td>
                          <td>
                            <strong>{{ $store.getters.getCart.total | priceFormat }} RSD</strong>
                          </td>
                        </tr>
                        <tr>
                          <td><b>Dostava</b></td>
                          <td>
                            {{ $store.getters.getCart.total > 5999 ? "0,00" : "390,00" }}
                            RSD

                            <input
                              type="hidden"
                              name="shipping"
                              :value="$store.getters.getCart.total > 5999 ? 0 : 390"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td><b>UKUPNO SA DOSTAVOM</b></td>
                          <td>
                            <strong>
                              {{
                                ($store.getters.getCart.total < 6000
                                  ? $store.getters.getCart.total + 390
                                  : $store.getters.getCart.total) | priceFormat
                              }}
                              RSD</strong
                            >

                            <input
                              type="hidden"
                              name="totalPrice"
                              :value="
                                $store.getters.getCart.total < 6000
                                  ? $store.getters.getCart.total + 390
                                  : $store.getters.getCart.total
                              "
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <!-- Order Payment Method -->
                  <div class="order-payment-method">
                    <div class="single-payment-method" :class="{ show: paymentMethod === 'cashOnDelivery' }">
                      <div class="payment-method-name">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="cashon"
                            name="customer_paymentmethod"
                            value="cashOnDelivery"
                            class="custom-control-input"
                            v-model="paymentMethod"
                            checked
                          />
                          <label class="custom-control-label" for="cashon">Plaćanje pouzećem</label>
                        </div>
                      </div>
                      <div class="payment-method-details" v-if="paymentMethod === 'cashOnDelivery'">
                        <p>Plaćanje kada roba stigne na Vašu adresu.</p>
                      </div>
                    </div>

                    <!-- <div class="single-payment-method">
                      <div
                        class="payment-method-name"
                        :class="{ show: paymentMethod === 'pickup' }"
                      >
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="pickup"
                            name="paymentMethod"
                            value="pickup"
                            class="custom-control-input"
                            v-model="paymentMethod"
                          />
                          <label class="custom-control-label" for="pickup"
                            >Pokupiti porudžbinu</label
                          >
                        </div>
                      </div>
                      <div
                        class="payment-method-details"
                        v-if="paymentMethod === 'pickup'"
                      >
                        <p>Sami pokupljate robu na adresu firme.</p>
                      </div>
                    </div>

                    <div class="single-payment-method">
                      <div
                        class="payment-method-name"
                        :class="{ show: paymentMethod === 'card' }"
                      >
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            id="paypalpayment"
                            name="paymentMethod"
                            value="card"
                            class="custom-control-input"
                            v-model="paymentMethod"
                          />
                          <label
                            class="custom-control-label"
                            for="paypalpayment"
                            >Plaćanje karticom
                            <img
                              src="../assets/img/paypal-card.jpg"
                              class="img-fluid paypal-card"
                              alt="Paypal"
                          /></label>
                        </div>
                      </div>
                      <div
                        class="payment-method-details"
                        v-if="paymentMethod === 'card'"
                      >
                        <p>Plaćanje karticom online.</p>
                      </div>
                    </div> -->

                    <div class="summary-footer-area">
                      <div class="custom-control custom-checkbox mb-20">
                        <input type="checkbox" class="custom-control-input" id="terms" required v-model="readTerms" />
                        <label class="custom-control-label" for="terms"
                          >Pročitao sam i prihvatam <router-link to="/">uslove korišćenja.</router-link></label
                        >
                      </div>

                      <button type="submit" class="btn btn__bg" v-if="!loading">Poruči</button>

                      <button type="button" disabled class="btn btn__bg" v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Poruči
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- checkout main wrapper end -->
  </main>
  <!-- main wrapper end -->
</template>

<script>
import { ordersCollection, timeStamp } from "../firebaseConfig";
import emailjs from "emailjs-com";

export default {
  metaInfo: {
    title: "Kasa",
  },
  data() {
    return {
      loading: false,
      paymentMethod: "cashOnDelivery",
      readTerms: false,
      shipping: 0,
      formData: {
        customer_first_name: "",
        customer_last_name: "",
        customer_email: "",
        customer_phone: "",
        customer_company: "",
        customer_address: "",
        customer_city: "",
        customer_zip: "",
        customer_ordernote: "",
      },
    };
  },
  methods: {
    submitOrder() {
      this.loading = true;
      const prodArr = [...this.$store.getters.getCart.products];

      const data = {
        ...this.formData,
        paymentMethod: this.paymentMethod,
        products: prodArr.map((el) => {
          const obj = {
            productId: el.id,
            quantity: el.quantity,
          };

          return obj;
        }),
        shipping: this.shipping,
        status: "PENDING",
        delivered: false,
        totalPrice: this.$store.getters.getCart.total + this.shipping,
        orderDate: timeStamp.fromDate(new Date()),
      };

      ordersCollection
        .add(data)
        .then((res) => {
          console.log("res", res.id);
          document.getElementById("orderId").value = res.id;

          const submitOrderForm = document.getElementById("submitOrderForm");

          emailjs
            .sendForm("service_thecdnb", "template_fljxzte", submitOrderForm, "user_QtRYrcF5gUGPelQxuU2R3")
            .then(() => {
              this.$store.dispatch("emptyCart");
              this.loading = false;
              window.location.href = "/uspesno";
            })
            .catch(() => {
              this.$store.dispatch("emptyCart");
              this.loading = false;
              window.location.href = "/uspesno";
            });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.single-payment-method .payment-method-details {
  display: block !important;
}
</style>
